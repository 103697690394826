import React, {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import TechnicalAdvisorsRoutesDefinition from "../admin/technical-advisors/TechnicalAdvisorsRoutesDefinition";
import LineOfBusinessesPagesDefinition from "../admin/line-of-businesses/LineOfBusinessesRoutesDefinition";
import InterviewsContentRoutesDefinition from "../admin/interviews-content/InterviewsContentRoutesDefinition";
import {SearchForInterviewPage} from "../pages/search/SearchForInterviewPage";
import {QuestionsForInterviewPage} from "../pages/interview-content-questions/QuestionsForInterviewPage";
import GroupsRoutesDefinition from "../admin/knowledges/groups/GroupsRoutesDefinition";
import TechnicalWordsRoutesDefinition from "../admin/knowledges/technical-words/TechnicalWordsRoutesDefinition";
import UsersRoutesDefinition from "../admin/users/UsersRoutesDefinition";
import {SelfEditPage} from "../admin/technical-advisors/technical-advisor-edit/SelfEditPage";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}
        <Route path='search-for-interview' element={<SearchForInterviewPage />} />
        <Route path='interview-content-questions' element={<QuestionsForInterviewPage />} />
        <Route path='/technical-advisors/self' element={<SelfEditPage />} />
        <Route path='/admin/technical-advisors/*' element={<TechnicalAdvisorsRoutesDefinition />} />
        <Route path='/admin/line-of-businesses/*' element={<LineOfBusinessesPagesDefinition />} />
        <Route path='/admin/interviews-content/*' element={<InterviewsContentRoutesDefinition />} />
        <Route path='/admin/knowledges/groups/*' element={<GroupsRoutesDefinition />} />
        <Route path='/admin/knowledges/technical-words/*' element={<TechnicalWordsRoutesDefinition />} />
        <Route path='/admin/users/*' element={<UsersRoutesDefinition />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
        <Route index element={<Navigate to='/search-for-interview' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
