/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KnowledgeGroupTechnicalWordCommand } from '../models/KnowledgeGroupTechnicalWordCommand';
import type { KnowledgeGroupTechnicalWordDto } from '../models/KnowledgeGroupTechnicalWordDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class KnowledgeGroupTechnicalWordService {

    /**
     * @param id 
     * @returns KnowledgeGroupTechnicalWordDto OK
     * @throws ApiError
     */
    public static findByIdKnowledgeGroupTechnicalWord(
id: string,
): CancelablePromise<KnowledgeGroupTechnicalWordDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/technical-words/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns KnowledgeGroupTechnicalWordDto OK
     * @throws ApiError
     */
    public static updateKnowledgeGroupTechnicalWord(
id: string,
requestBody: KnowledgeGroupTechnicalWordCommand,
): CancelablePromise<KnowledgeGroupTechnicalWordDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/knowledge-groups/technical-words/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @returns KnowledgeGroupTechnicalWordDto OK
     * @throws ApiError
     */
    public static deleteKnowledgeGroupTechnicalWord(
id: string,
): CancelablePromise<KnowledgeGroupTechnicalWordDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/knowledge-groups/technical-words/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns KnowledgeGroupTechnicalWordDto Created
     * @throws ApiError
     */
    public static createKnowledgeGroupTechnicalWord(
requestBody: KnowledgeGroupTechnicalWordCommand,
): CancelablePromise<KnowledgeGroupTechnicalWordDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/knowledge-groups/technical-words',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param word 
     * @returns KnowledgeGroupTechnicalWordDto OK
     * @throws ApiError
     */
    public static findAllByTechnicalWord(
word: string,
): CancelablePromise<Array<KnowledgeGroupTechnicalWordDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/technical-words/byTechnicalWord',
            query: {
                'word': word,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param group 
     * @returns KnowledgeGroupTechnicalWordDto OK
     * @throws ApiError
     */
    public static findAllByKnowledgeGroupKnowledgeGroupTechnicalWord(
group: string,
): CancelablePromise<Array<KnowledgeGroupTechnicalWordDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/technical-words/byKnowledgeGroup',
            query: {
                'group': group,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns KnowledgeGroupTechnicalWordDto OK
     * @throws ApiError
     */
    public static findAllKnowledgeGroupTechnicalWord(): CancelablePromise<Array<KnowledgeGroupTechnicalWordDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/technical-words/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
