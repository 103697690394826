import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery} from "react-query";
import {
    InterviewContentSubjectService,
    InterviewContentThematicService
} from "../../../../services/requests";
import {useLocation} from "react-router";
import {PageTitle} from "../../../../_metronic/layout/core";

type Params = {
    id?: ID
    thematic: string
    lineOfBusiness: string
}

const SubjectEditPage: FC = () => {
    const location = useLocation();
    const locationState = location?.state as Params;
    const intl = useIntl()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

    const [subjectId, setSubjectId] = useState(locationState?.id);
    const [lineOfBusiness, setLineOfBusiness] = useState(locationState?.lineOfBusiness);
    const [thematic, setThematic] = useState(locationState?.thematic);


    const {data: subject, isLoading: isLoading, refetch: refetchSubject} = useQuery({
            queryKey: ['subject', subjectId],
            queryFn: () => {
                return InterviewContentSubjectService.findByIdInterviewContentSubject(subjectId as string);
            },
            enabled: !!subjectId
        }
    )

    const everythingLoaded = !isLoading;


    const pageTitle = subject ?
        intl.formatMessage({id: 'PAGE.SUBJECT_EDIT.TITLE.UPDATE'}, {name: subject?.nameFr})
        : intl.formatMessage({id: 'PAGE.SUBJECT_EDIT.TITLE.NEW'});

    const refreshSubjectId = (newId: string) => {
        setSubjectId(newId);
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {pageTitle}
            </PageTitle>
            <div className='card mb-5'>
                <div className='card-body'>
                    {everythingLoaded && (
                        <div className='mx-auto mw-600px w-100 pt-5'>
                            <MainInfos lineOfBusiness={lineOfBusiness} refreshSubject={refetchSubject} refreshSubjectId={refreshSubjectId} thematic={thematic} subject={subject} />
                        </div>
                    )}
                </div>
            </div>
        </>

    )
}

export {SubjectEditPage}
