import {FC} from 'react'
import clsx from 'clsx'
import {
  ThemeModeSwitcher,
} from '../../../partials'
import {useLayout, usePageData} from '../../core'
import {Languages} from "../../../partials/layout/header-menus/Languages";
import {Link} from "react-router-dom";

const itemClass = 'ms-1 ms-lg-3',
  btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
  userAvatarClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const {config} = useLayout()
    const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()


  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
        {/* begin::Title */}
        {pageTitle && (!pageBreadcrumbs || pageBreadcrumbs.length == 0) && (
            <h1 className='d-flex align-items-center fw-bolder my-1 fs-3 text-primary'>
                {pageTitle}
                {pageDescription && config.pageTitle && config.pageTitle.description && (
                    <>
                        {/* begin::Separator */}
                        <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                        {/* end::Separator */}

                        <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
                    </>
                )}
            </h1>
        )}
        {/* end::Title */}
      {pageBreadcrumbs && pageBreadcrumbs.length > 0 && (
          <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 pt-1'>
            {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                    className={clsx('breadcrumb-item', {
                      'text-gray-900': !item.isSeparator && item.isActive,
                      'text-muted': !item.isSeparator && !item.isActive,
                    })}
                    key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                       <Link className='text-muted text-hover-primary' to={item.path}>
                        {item.title}
                       </Link>
                  ) : (
                      <span className='bullet bg-gray-500 w-5px h-2px'></span>
                  )}
                </li>
            ))}
            <li className='breadcrumb-item text-gray-900'>{pageTitle}</li>
          </ul>
      )}
    </div>
  )
}

export {Topbar}
