import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../../_metronic/helpers'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery, useQueryClient} from "react-query";
import {
    KnowledgeGroupLineOfBusinessService,
    KnowledgeGroupService, KnowledgeGroupTechnicalWordService, LineOfBusinessService, TechnicalWordService
} from "../../../../../services/requests";
import {useLocation} from "react-router";
import {PageLink, PageTitle} from "../../../../../_metronic/layout/core";
import {useAuth} from "../../../../modules/auth";
import {KnowledgeGroups} from "./components/KnowledgeGroups";
import {IntlLabelizer} from "../../../../../utils/IntlLabelizer";
import {useLang} from "../../../../../_metronic/i18n/Metronici18n";

type Params = {
  technicalWordId?: ID
}

const TechnicalWordEditPage: FC = () => {
  const location = useLocation();
  const locationState = location.state as Params;
  const intl = useIntl()
  const lang = useLang()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const queryClient = useQueryClient();

  const [technicalWordId, setTechnicalWordId] = useState(locationState.technicalWordId);

  const {data: groups, isLoading: isGroupsLoading} = useQuery({
        queryKey: ['groups'],
        queryFn: () => {
          return KnowledgeGroupService.findAllKnowledgeGroup();
        },
      enabled: !!technicalWordId
      }
  )

    const {data: associatedGroups, isLoading: isAssociatedGroupsLoading} = useQuery({
            queryKey: ['knowledgeGroupsTechnicalWord', technicalWordId],
            queryFn: () => {
                return KnowledgeGroupTechnicalWordService.findAllByTechnicalWord(technicalWordId as string);
            },
        enabled: !!technicalWordId
        }
    )

    const {
        isLoading: isKnowledgeGroupLoading,
        data: technicalWord,
    } = useQuery({
            queryKey: ['technicalWord', technicalWordId],
            queryFn: () => {
                return TechnicalWordService.findByIdTechnicalWord(technicalWordId as string);
            },
            enabled: !!technicalWordId
        }
    )


    const everythingLoaded = !technicalWordId ||
        technicalWord && groups && associatedGroups;

    const pageTitle = technicalWord ?
        intl.formatMessage({id: 'PAGE.KNOWLEDGES.TECHNICAL_WORD.TITLE.UPDATE'}, {name: IntlLabelizer.getIntlLabel(technicalWord, lang)})
        : intl.formatMessage({id: 'PAGE.KNOWLEDGES.TECHNICAL_WORD.TITLE.NEW'});

    const refreshTechnicalWordId = (wordId: string) => {
        setTechnicalWordId(wordId);
        queryClient.removeQueries({ queryKey: ['technicalWord', wordId] });

    }


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const changeTab = (newTabIndex: number) => {
      stepper.current?.goto(newTabIndex)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

    const breadCrumb: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'PAGE.KNOWLEDGES.TECHNICAL_WORDS.TITLE'}),
            path: '/admin/knowledges/technical-words',
            isSeparator: false,
            isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

  return (
      <>
        <PageTitle breadcrumbs={breadCrumb}>
            {pageTitle}
        </PageTitle>
        <div className='card mb-5'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-5'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav' onClick={() => changeTab(1)} role="button">
              <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.KNOWLEDGES.TECHNICAL_WORD_EDIT.MENU.MAIN_INFOS'})}</h3>
            </div>

                <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(2)} role="button">
                      {!!technicalWordId && (
                    <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.KNOWLEDGES.TECHNICAL_WORD_EDIT.MENU.GROUPS'})}</h3>
                      )}
                </div>
          </div>
            {everythingLoaded && (
            <div className='mx-auto mw-600px w-100 pt-5'>
                <div className='current' data-kt-stepper-element='content'>
                  <MainInfos refreshTechnicalWordId={refreshTechnicalWordId} technicalWord={technicalWord} />
                </div>
                <div data-kt-stepper-element='content'>
                    {technicalWord && groups && associatedGroups && (
                        <KnowledgeGroups technicalWord={technicalWord} groups={groups} associatedGroups={associatedGroups} />
                    )}
                </div>
            </div>
            )}
        </div>
      </div>
    </div>
    </>

  )
}

export {TechnicalWordEditPage}
