/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES, Response,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from './QueryRequestProvider'
import {
  TechnicalWordDto, TechnicalWordService,
} from "../../../../../../services/requests";
import {useIntl} from "react-intl";

const QueryResponseContext = createResponseContext<TechnicalWordDto>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const intl = useIntl()
  const pagination = useQueryResponsePagination()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])


  const { data: groups, isFetching, refetch } = useQuery({
    queryKey: ['technicalWords', state.group],
    queryFn: () => {
      // Do something here
      if(!!state.group) {
        return TechnicalWordService.findAllByKnowledgeGroup(state.group?state.group:'');
      } else {
        return TechnicalWordService.findAllTechnicalWord()
      }
    }
  });

  function filterTechnicalWords(searchString: string, words?: TechnicalWordDto[]): TechnicalWordDto[] {
    if(!words) {
      return [];
    }
    return words.filter(g =>
        g.labelFr?.toLowerCase().includes(searchString.toLowerCase())
        || g.labelEn?.toLowerCase().includes(searchString.toLowerCase())
    );
  }

  const technicalWordsUsed = !!state.search?filterTechnicalWords(state.search, groups):groups;


  const response: Response<Array<TechnicalWordDto>> = {data:technicalWordsUsed, pagination: pagination};

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.pagination) {
    return defaultPaginationState
  }

  return response.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
