import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../../_metronic/helpers'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery, useQueryClient} from "react-query";
import {
    KnowledgeGroupLineOfBusinessService,
    KnowledgeGroupService, LineOfBusinessService
} from "../../../../../services/requests";
import {useLocation} from "react-router";
import {PageLink, PageTitle} from "../../../../../_metronic/layout/core";
import {useAuth} from "../../../../modules/auth";
import {LineOfBusinesses} from "./components/LineOfBusinesses";
import {IntlLabelizer} from "../../../../../utils/IntlLabelizer";
import {useLang} from "../../../../../_metronic/i18n/Metronici18n";

type Params = {
  groupId?: ID
}

const GroupEditPage: FC = () => {
  const location = useLocation();
  const locationState = location.state as Params;
  const intl = useIntl()
    const lang = useLang()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
    const queryClient = useQueryClient()

  const {currentUser} = useAuth()
    const isCurrentUserAdmin = !!currentUser?.roles?.find(role => role == "ADMIN");

    const [groupId, setGroupId] = useState(locationState.groupId);

  const {data: lineOfBusinesses, isLoading: isLineOfBusinessesLoading} = useQuery({
        queryKey: ['lineOfBusinesses'],
        queryFn: () => {
          return LineOfBusinessService.findAllLineOfBusiness();
        },
      enabled: !!groupId
      }
  )

    const {data: associatedLineOfBusinesses, isLoading: isAssociatedLineOfBusinessesLoading} = useQuery({
            queryKey: ['knowledgGrouplineOfBusinesses', groupId],
            queryFn: () => {
                return KnowledgeGroupLineOfBusinessService.findAllByKnowledgeGroupKnowledgeGroupLineOfBusiness(groupId as string);
            },
        enabled: !!groupId
        }
    )

    const {
        isLoading: isKnowledgeGroupLoading,
        data: knowledgeGroup,
    } = useQuery({
            queryKey: ['knowledgeGroup', groupId],
            queryFn: () => {
                return KnowledgeGroupService.findByIdKnowledgeGroup(groupId as string);
            },
            enabled: !!groupId
        }
    )

    // const { data: technicalWords, isLoading: isTechnicalWordsLoading } = useQuery({
    //     queryKey: ['technicalWords', locationState.lineOfBusinessId],
    //     queryFn: () => {
    //         // Do something here
    //         //Todo change it
    //         return TechnicalWordService.findAllTechnicalWord();
    //     }
    // });
    //


    const everythingLoaded = !groupId ||
        knowledgeGroup && lineOfBusinesses && associatedLineOfBusinesses;

    const pageTitle = knowledgeGroup ?
        intl.formatMessage({id: 'PAGE.KNOWLEDGES.GROUP.TITLE.UPDATE'}, {name: IntlLabelizer.getIntlLabel(knowledgeGroup, lang)})
        : intl.formatMessage({id: 'PAGE.KNOWLEDGES.GROUP.TITLE.NEW'});

    const refreshKnowledgeGroupId = (newKnowledgeGroupId: string) => {
        setGroupId(newKnowledgeGroupId);
        queryClient.removeQueries({ queryKey: ['knowledgeGroup', newKnowledgeGroupId] });
        queryClient.removeQueries({ queryKey: ['knowledgGrouplineOfBusinesses', groupId] });
    }


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const changeTab = (newTabIndex: number) => {
      stepper.current?.goto(newTabIndex)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

    const breadCrumb: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'PAGE.KNOWLEDGES.GROUPS.TITLE'}),
            path: '/admin/knowledges/groups',
            isSeparator: false,
            isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

  return (
      <>
        <PageTitle breadcrumbs={breadCrumb}>
            {pageTitle}
        </PageTitle>
        <div className='card mb-5'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-5'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav' onClick={() => changeTab(1)} role="button">
              <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.KNOWLEDGES.GROUP_EDIT.MENU.MAIN_INFOS'})}</h3>
            </div>

                <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(2)} role="button">
                      {!!groupId && (
                    <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.KNOWLEDGES.GROUP_EDIT.MENU.LINE_OF_BUSINESSES'})}</h3>
                      )}
                </div>

              {/*{isCurrentUserAdmin && (*/}
              {/*    <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(3)} role="button">*/}
              {/*        {!!groupId && (*/}
              {/*            <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.KNOWLEDGES.GROUP_EDIT.MENU.TECHNICAL_WORDS'})}</h3>*/}
              {/*        )}*/}
              {/*    </div>*/}
              {/*)}*/}

          </div>
            {everythingLoaded && (
            <div className='mx-auto mw-600px w-100 pt-5'>
                <div className='current' data-kt-stepper-element='content'>
                  <MainInfos refreshKnowledgeGroupId={refreshKnowledgeGroupId} knowledgeGroup={knowledgeGroup} />
                </div>
                <div data-kt-stepper-element='content'>
                    {knowledgeGroup && lineOfBusinesses && associatedLineOfBusinesses && (
                        <LineOfBusinesses knowledgeGroup={knowledgeGroup} lineOfBusinesses={lineOfBusinesses} associatedLineOfBusinesses={associatedLineOfBusinesses} />
                    )}
                </div>
                {/*{isCurrentUserAdmin && (*/}
                {/*    <div data-kt-stepper-element='content'>*/}
                {/*        {technicalAdvisor  && (*/}
                {/*            <TechnicalWords technicalAdvisor={technicalAdvisor} />*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
            )}
        </div>
      </div>
    </div>
    </>

  )
}

export {GroupEditPage}
