/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES, Response,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getUsers} from './_requests'
import {User} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import {TechnicalAdvisorDto, TechnicalAdvisorService} from "../../../../../services/requests";
import {Pagination} from "react-bootstrap";
import {useIntl} from "react-intl";

const QueryResponseContext = createResponseContext<TechnicalAdvisorDto>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const intl = useIntl()
  const pagination = useQueryResponsePagination()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])


  const { data: technicalAdvisors, isFetching, refetch } = useQuery({
    queryKey: ['technicalAdvisors', state.lineOfBusiness],
    queryFn: () => {
      // Do something here

      return TechnicalAdvisorService.findAllByLineOfBusinessTechnicalAdvisor(state.lineOfBusiness?state.lineOfBusiness:'');
    },
    enabled: !!state.lineOfBusiness
  });

  function filterTechnicalAdvisors(searchString: string, technicalAdvisors?: TechnicalAdvisorDto[]): TechnicalAdvisorDto[] {
    if(!technicalAdvisors) {
      return [];
    }
    return technicalAdvisors.filter(ta =>
        ta.name?.toLowerCase().includes(searchString.toLowerCase())
        || ta.email?.toLowerCase().includes(searchString.toLowerCase())
        || ta.employeeId?.toLowerCase().includes(searchString.toLowerCase())
        || intl.formatMessage({id: 'ENUM.REGION'}, {region: ta?.region}).toLowerCase().includes(searchString.toLowerCase())
    );
  }

  const technicalAdvisorsUsed = !!state.search?filterTechnicalAdvisors(state.search, technicalAdvisors):technicalAdvisors;


  const response: Response<Array<TechnicalAdvisorDto>> = {data:technicalAdvisorsUsed, pagination: pagination};

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.pagination) {
    return defaultPaginationState
  }

  return response.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
