import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {Formik, FormikValues, useFormik} from 'formik'
import {ID, isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialTechnicalWord} from '../core/_models'
import clsx from 'clsx'
import {FormattedMessage, useIntl} from "react-intl";
import {
    KnowledgeGroupCommand,
    KnowledgeGroupDto,
    KnowledgeGroupService, TechnicalWordCommand,
    TechnicalWordDto, TechnicalWordService
} from "../../../../../../services/requests";
import {useToastData} from "../../../../../../utils/ToastNotificator";

type Props = {
    technicalWord?: TechnicalWordDto
    refreshTechnicalWordId: Function
}

const editTechnicalWordSchema = Yup.object().shape({
    labelFr: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Name is required'),
    labelEn: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Name is required')
})
const MainInfos: FC<Props> = ({refreshTechnicalWordId, technicalWord}) => {

    const intl = useIntl()
    const {showSuccessMessage, showErrorMessage} = useToastData();




    const [technicalWordForEdit] = useState<TechnicalWordCommand>({
        ...technicalWord,
        labelFr: technicalWord?.labelFr || initialTechnicalWord.labelFr,
        labelEn: technicalWord?.labelEn || initialTechnicalWord.labelEn,
    })


    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
        }
    }




    const submitForm = async (values, {setSubmitting}) => {

            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await TechnicalWordService.updateTechnicalWord(values.id as string, values)
                } else {
                    const res = await TechnicalWordService.createTechnicalWord(values)
                    refreshTechnicalWordId(res.id)

                }
                showSuccessMessage();
            } catch (ex) {
                showErrorMessage();
                console.error(ex)
            } finally {
                //setSubmitting(true)
                //cancel(true)
            }
    }

    return (
        <>
            <Formik
                initialValues={technicalWordForEdit} validationSchema={editTechnicalWordSchema} onSubmit={submitForm}
                >
                {props => (
            <form className='w-100' onSubmit={props.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.KNOWLEDGES.TECHNICAL_WORD_EDIT.LABEL_FR'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('labelFr')}
                            type='text'
                            name='labelFr'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.labelFr && props.errors.labelFr},
                                {
                                    'is-valid': props.touched.labelFr && !props.errors.labelFr,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.labelFr && props.errors.labelFr && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.labelFr}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.KNOWLEDGES.TECHNICAL_WORD_EDIT.LABEL_EN'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('labelEn')}
                            type='text'
                            name='labelEn'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.labelEn && props.errors.labelEn},
                                {
                                    'is-valid': props.touched.labelEn && !props.errors.labelEn,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.labelEn && props.errors.labelEn && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.labelEn}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}


                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={props.isSubmitting || !props.isValid || !props.touched}
                    >
                        {(!props.isSubmitting) && (
                            <span className='indicator-label'>{intl.formatMessage({id: 'FORM.ACTION.SAVE'})}</span>
                        )}
                        {(props.isSubmitting) && (
                            <span>
                                {intl.formatMessage({id: 'COMMON.PLEASE_WAIT'})}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            )}
            </Formik>



        </>
    )
}

export {MainInfos}
