import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {Formik, FormikValues, useFormik} from 'formik'
import {ID, isNotEmpty} from '../../../../../_metronic/helpers'
import {initialTechnicalAdvisor} from '../core/_models'
import clsx from 'clsx'
import {
    GetTechnicalAdvisorsForInterview,
    JobPositionService,
    LineOfBusinessDto,
    LineOfBusinessService,
    TechnicalAdvisorChangeBlockedStatusCommand,
    TechnicalAdvisorCommand,
    TechnicalAdvisorDto,
    TechnicalAdvisorSAcceptanceSkillService,
    TechnicalAdvisorService,
    TechnicalWordDto,
    TechnicalWordService
} from "../../../../../services/requests";
import {FormattedMessage, useIntl} from "react-intl";
import Select from "react-select";
import {useQuery} from "react-query";
import {useToastData} from "../../../../../utils/ToastNotificator";

type Props = {
    technicalAdvisor: TechnicalAdvisorDto
}

const BlockingInfos: FC<Props> = ({technicalAdvisor}) => {

    const intl = useIntl()
    const {showSuccessMessage, showErrorMessage} = useToastData();




    const [technicalAdvisorForChangeBlockedStatusEdit] = useState<TechnicalAdvisorChangeBlockedStatusCommand>({
        technicalAdvisor: technicalAdvisor?.id,
        blocked: technicalAdvisor?.blocked || false,
        blockedComment: technicalAdvisor?.blockedComment || "",
    })




    const submitForm = async (values, {setSubmitting}) => {

            setSubmitting(true)
            try {
                await TechnicalAdvisorService.changeBlockedStatus(values)
                showSuccessMessage();
            } catch (ex) {
                showErrorMessage();
                console.error(ex)
            } finally {
                setSubmitting(false)
            }
    }

    return (
        <>
            <Formik
                initialValues={technicalAdvisorForChangeBlockedStatusEdit} onSubmit={submitForm}
                >
                {props => (
            <form className='w-100' onSubmit={props.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.BLOCKED'})}</label>
                        <div className='form-check form-check-solid form-switch fv-row'>
                            <input
                                {...props.getFieldProps('blocked')}
                                className='form-check-input w-60px h-30px'
                                type='checkbox'
                                data-cy='isBlocked'
                                defaultChecked={props.values.blocked}
                            />
                        </div>
                        {/* end::Label */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.BLOCKED_COMMENT'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <textarea
                            {...props.getFieldProps('blockedComment')}
                            name='blockedComment'
                            data-cy='blockedComment'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.blockedComment && props.errors.blockedComment},
                                {
                                    'is-valid': props.touched.blockedComment && !props.errors.blockedComment,
                                }
                            )}
                            disabled={props.isSubmitting}
                        />
                        {props.touched.blockedComment && props.errors.blockedComment && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.blockedComment}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}



                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={props.isSubmitting || !props.isValid || !props.touched}
                    >
                        {(!props.isSubmitting) && (
                            <span className='indicator-label'>{intl.formatMessage({id: 'FORM.ACTION.SAVE'})}</span>
                        )}
                        {(props.isSubmitting) && (
                            <span>
                                {intl.formatMessage({id: 'COMMON.PLEASE_WAIT'})}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            )}
            </Formik>



        </>
    )
}

export {BlockingInfos}
