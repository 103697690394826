import {PageTitle} from "../../../_metronic/layout/core";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {SearchForm, SearchFormValues} from "./components/SearchForm";
import {SearchResults} from "./components/SearchResults";
import {useQuery} from "react-query";
import {
    JobPositionService,
    LineOfBusinessDto,
    LineOfBusinessService,
    TechnicalWordService
} from "../../../services/requests";
import {useAuth} from "../../modules/auth";


export const SearchForInterviewPage = () => {
    const intl = useIntl();

    // const {currentUser} = useAuth()
    //
    // const [lineOfBusiness, setLineOfBusiness] = useState<string>();
    // const [jobPosition, setJobPosition] = useState<string>();
    const [currentSearch, setCurrentSearch] = useState<SearchFormValues>();

    // const { data: lineOfBusinesses, isLoading: isLineOfBusinessesLoading } = useQuery({
    //     queryKey: ['lineOfBusinesses'],
    //     queryFn: LineOfBusinessService.findAllLineOfBusiness,
    //     staleTime: 300000
    // })
    //
    // const canUserSearchLineOfBusiness = (lineOfBusinessToCheck: string) => {
    //     return(
    //         ((!currentUser?.lineOfBusinesses || currentUser?.lineOfBusinesses.length == 0) || !!currentUser?.lineOfBusinesses?.find(lob => lob == lineOfBusinessToCheck) )
    //     )
    //
    // }
    //
    // const lineOfBusinessesToUse = lineOfBusinesses?.filter(lob => canUserSearchLineOfBusiness(lob.id));
    //
    // console.log("Component rendered ?");
    //
    //
    // const { data: jobPositions, isLoading: isJobPositionsLoading } = useQuery({
    //     queryKey: ['jobPositions', lineOfBusiness],
    //     queryFn: () => {
    //         // Do something here
    //         if(!!lineOfBusiness) {
    //             return JobPositionService.findAllByLineOfBusinessJobPosition(lineOfBusiness as string);
    //         } else {
    //             return JobPositionService.findAllJobPosition();
    //         }
    //
    //     },
    //     staleTime: 300000
    // });
    //
    // const { data: technicalWords, isLoading: isTechnicalWordsLoading } = useQuery({
    //     queryKey: ['technicalWords', jobPosition],
    //     queryFn: () => {
    //         // Do something here
    //         // Todo change it
    //
    //         return TechnicalWordService.findAllByKnowledgeGroups([]);
    //     },
    //     enabled: !!jobPosition,
    //     staleTime: 300000
    // });
    //
    // const everythingIsLoaded = !isLineOfBusinessesLoading && !isJobPositionsLoading;

    // const manageLineOfBusinessChange = (lineOfBusiness: string) : void => {
    //     setLineOfBusiness(lineOfBusiness);
    // }
    //
    // const manageJobPositionChange = (jobPosition: string) : void => {
    //     //setJobPosition(jobPosition);
    // }

    const launchSearch = (values: SearchFormValues) => {
        setCurrentSearch(values);
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({id: 'PAGE.SEARCH.TITLE'})}
            </PageTitle>
            <div className="card my-5">
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1 text-primary'>{intl.formatMessage({id: 'FORM.SEARCH.TITLE'})}</span>
                    </h3>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    { <SearchForm
                        onSubmit={launchSearch}
                    />}
                </div>
                {/* end::Body */}
            </div>
            {currentSearch && (
            <div className="card my-5">
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1 text-primary'>{intl.formatMessage({id: 'TABLE.TECHNICAL_ADVISORS_ELIGIBLES.TITLE'})}</span>
                    </h3>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                        <SearchResults
                            technicalWords={[]}
                            searchValues={currentSearch}
                        />
                </div>
                {/* end::Body */}
            </div>
            )}
        </>
    )

}

