/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from "react-router-dom";
import {ID} from "../../../../../../../_metronic/helpers";
import {useQuery} from "react-query";
import {KnowledgeGroupService} from "../../../../../../../services/requests";
import {IntlLabelizer} from "../../../../../../../utils/IntlLabelizer";
import {useLang} from "../../../../../../../_metronic/i18n/Metronici18n";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

type Props = {
    groups: string[],
    id: ID,
    lang: string
}

const TechnicalWordGroupsCell: FC<Props> = ({groups, id, lang}) => {

    const { data: loadedGroups, isLoading } = useQuery({
        queryKey: ['groups'],
        queryFn: KnowledgeGroupService.findAllKnowledgeGroup,
    })

    return (
         <>
             {!isLoading && (

                    <div className='d-flex align-items-center'>
                        <div className='d-flex flex-column'>
                            <>
                                {groups.length > 0 && (
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props) => (
                                        <Tooltip id="button-tooltip" {...props}>
                                            {
                                                groups.map(g => loadedGroups?.find(lg => lg.id == g))
                                                    .filter(lg => lg != undefined)
                                                    .map(lg => (
                                                            <div>
                                                                {IntlLabelizer.getIntlLabel(lg, lang)}<br />
                                                            </div>
                                                        )
                                                    )
                                            }
                                        </Tooltip>
                                    )}
                                >
                                     <span className="bg-transparent border-0 text-info cursor-pointer">
                                        &nbsp;&nbsp;&nbsp; { groups.length }&nbsp;&nbsp;&nbsp;
                                     </span>
                                </OverlayTrigger>
                            )}
                            </>
                        </div>
                    </div>
                )
             }
         </>

    )
}

export {TechnicalWordGroupsCell}
