import {ListViewProvider, useListView} from './core/ListViewProvider'
import {KTCard} from '../../../../_metronic/helpers'
import {useState} from "react";
import {UsersListHeader} from "./components/UsersListHeader";
import {QueryRequestProvider} from "./core/QueryRequestProvider";
import {QueryResponseProvider} from "./core/QueryResponseProvider";
import {UsersTable} from "./table/UsersTable";

const UsersListPage = () => {
  const {itemIdForUpdate} = useListView()
  const [currentLineOfBusiness, setCurrentLineOfBusiness] = useState('');



  return (
    <>
      <KTCard className='mt-5'>
        <UsersListHeader />
        <UsersTable />
      </KTCard>
    </>
  )
}

const UsersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersListPage />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersListWrapper}
