import {KTIcon} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {useIntl} from "react-intl";
import {useQueryRequest} from "../core/QueryRequestProvider";
import {useNavigate} from "react-router-dom";

const UsersListToolbar = () => {
    const intl = useIntl();
    const {state} = useQueryRequest();
    const navigate = useNavigate();

  const goToAdd = () => {
        navigate('/admin/users/edit', {state: {}});
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {/* begin::Add user */}
        <button type='button' className='btn btn-primary' onClick={goToAdd}>
        <KTIcon iconName='plus' className='fs-2' />
        {intl.formatMessage({id: 'TABLE.USERS.NEW'})}
        </button>
        {/* end::Add user */}

    </div>
  )
}

export {UsersListToolbar}
