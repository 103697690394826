/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from "react-router-dom";
import {ID} from "../../../../../../../_metronic/helpers";

type Props = {
    label: string,
    id: ID
}

const GroupLabelCell: FC<Props> = ({label, id}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
        <Link to='/admin/knowledges/groups/edit' className='text-dark fw-bold text-hover-primary fs-6' state={{groupId: id}} >{label}</Link>
    </div>
  </div>
)

export {GroupLabelCell}
