import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {Formik, FormikValues, useFormik} from 'formik'
import {ID, isNotEmpty} from '../../../../../_metronic/helpers'
import {initialUser} from '../core/_models'
import clsx from 'clsx'
import {FormattedMessage, useIntl} from "react-intl";
import Select from "react-select";
import {
    LineOfBusinessDto,
    UserInfoCommand,
    UserInfoDto, UserRoleDto,
    UserService
} from "../../../../../services/requests";
import {IntlLabelizer} from "../../../../../utils/IntlLabelizer";
import {useLang} from "../../../../../_metronic/i18n/Metronici18n";
import {useToastData} from "../../../../../utils/ToastNotificator";

type Props = {
    user?: UserInfoDto
    refreshUserId: Function
    lineOfBusinesses: LineOfBusinessDto[]
    availableRoles: UserRoleDto[]
}

const editUserSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 50 symbols'),
})
const MainInfos: FC<Props> = ({refreshUserId, user, lineOfBusinesses, availableRoles}) => {

    const intl = useIntl()
    const lang = useLang()
    const {showSuccessMessage, showErrorMessage} = useToastData();


    const [userForEdit] = useState<UserInfoCommand>({
        ...user,
        name: user?.name || initialUser.name,
        email: user?.email || initialUser.email,
        active: user? user?.active as boolean:initialUser.active,
        roles: [],
        lineOfBusinesses: []
    })


    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
        }
    }




    const submitForm = async (values, {setSubmitting}) => {

            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await UserService.updateUser(values.id as string, values)
                } else {
                    const res = await UserService.createUser(values)
                    refreshUserId(res.id)

                }
                showSuccessMessage();
            } catch (ex) {
                showErrorMessage();
                console.error(ex)
            } finally {
                //setSubmitting(true)
                //cancel(true)
            }
    }

    return (
        <>
            <Formik
                initialValues={userForEdit} validationSchema={editUserSchema} onSubmit={submitForm}
                >
                {props => (
            <form className='w-100' onSubmit={props.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.USER_EDIT.NAME'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('name')}
                            type='text'
                            name='name'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.name && props.errors.name},
                                {
                                    'is-valid': props.touched.name && !props.errors.name,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.name && props.errors.name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.name}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.USER_EDIT.EMAIL'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('email')}
                            type='text'
                            name='email'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.email && props.errors.email},
                                {
                                    'is-valid': props.touched.email && !props.errors.email,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.email && props.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.email}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    {!!lineOfBusinesses && lineOfBusinesses.length > 0 && (
                        <div className='fv-row mb-7'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.USER_EDIT.LINE_OF_BUSINESSES'})}</label>

                            <Select
                                name='lineOfBusinesses'
                                options={lineOfBusinesses.map(lob => {
                                    return {
                                        value: lob.id,
                                        label: IntlLabelizer.getIntlLabel(lob, lang)
                                    };})}
                                isMulti
                                onChange={(e) => (
                                    props.values.lineOfBusinesses = e.map((elt) => elt.value!)
                                )}
                                defaultValue={user?.lineOfBusinesses?.map(lob => {
                                    return {
                                        value: lob,
                                        label: IntlLabelizer.getIntlLabel(lineOfBusinesses.find(tw => tw.id == lob), lang)
                                    };})}
                            />
                            {/* end::Label */}
                        </div>
                    )}
                    {/* end::Input group */}

                    {!!availableRoles && availableRoles.length > 0 && (
                        <div className='fv-row mb-7'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.USER_EDIT.ROLES'})}</label>

                            <Select
                                name='roles'
                                options={availableRoles.map(role => {
                                    return {
                                        value: role.code as string,
                                        label: IntlLabelizer.getIntlLabel(role, lang)
                                    };})}
                                isMulti
                                onChange={(e) => (
                                    props.values.roles = e.map((elt) => elt.value!)
                                )}
                                defaultValue={user?.roles?.map(roleCode => {
                                    return {
                                        value: roleCode,
                                        label: IntlLabelizer.getIntlLabel(availableRoles.find(tw => tw.code == roleCode), lang)
                                    };})}
                            />
                            {/* end::Label */}
                        </div>
                    )}
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.USER_EDIT.ACTIVE'})}</label>
                        <div className='form-check form-check-solid form-switch fv-row'>
                            <input
                                {...props.getFieldProps('active')}
                                className='form-check-input w-60px h-30px'
                                type='checkbox'
                                defaultChecked={props.values.active}
                            />
                        </div>
                        {/* end::Label */}
                    </div>
                    {/* end::Input group */}


                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={props.isSubmitting || !props.isValid || !props.touched}
                    >
                        {(!props.isSubmitting) && (
                            <span className='indicator-label'>{intl.formatMessage({id: 'FORM.ACTION.SAVE'})}</span>
                        )}
                        {(props.isSubmitting) && (
                            <span>
                                {intl.formatMessage({id: 'COMMON.PLEASE_WAIT'})}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            )}
            </Formik>



        </>
    )
}

export {MainInfos}
