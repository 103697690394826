// @ts-nocheck
import {Column} from 'react-table'
import {GroupLabelCell} from './GroupLabelCell'
import {GroupActionsCell} from './GroupActionsCell'
import {GroupCustomHeader} from './GroupCustomHeader'
import {JobPositionDto} from "../../../../../../../services/requests";
import {IntlLabelizer} from "../../../../../../../utils/IntlLabelizer";
import {useLang} from "../../../../../../../_metronic/i18n/Metronici18n";



const jobPositionsColumns: ReadonlyArray<Column<JobPositionDto>> = [
  {
    Header: (props) => <GroupCustomHeader tableProps={props} title='TABLE.JOB_POSITIONS.HEADER.LABEL_FR' className='min-w-125px' />,
    id: 'labelFr',
    Cell: ({...props}) => <GroupLabelCell label={props.data[props.row.index].labelFr} id={props.data[props.row.index].id} lineOfBusiness={props.data[props.row.index].lineOfBusiness} />,
  },
  {
    Header: (props) => <GroupCustomHeader tableProps={props} title='TABLE.JOB_POSITIONS.HEADER.LABEL_EN' className='min-w-125px' />,
    id: 'labelEn',
    Cell: ({...props}) => <GroupLabelCell label={props.data[props.row.index].labelEn} id={props.data[props.row.index].id} lineOfBusiness={props.data[props.row.index].lineOfBusiness} />,
  },
  {
    Header: (props) => <GroupCustomHeader tableProps={props} title='TABLE.HEADER.ACTIONS' className='min-w-125px' />,
    id: 'actions',
    Cell: ({...props}) => <GroupActionsCell id={props.data[props.row.index].id} label={IntlLabelizer.getIntlLabel(props.data[props.row.index], useLang())}  />,
  }
]

export {jobPositionsColumns}
