/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InterviewContentQuestionPublicCommand } from '../models/InterviewContentQuestionPublicCommand';
import type { InterviewContentQuestionPublicDto } from '../models/InterviewContentQuestionPublicDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InterviewContentQuestionSPublicService {

    /**
     * @param id 
     * @returns InterviewContentQuestionPublicDto OK
     * @throws ApiError
     */
    public static findByIdInterviewContentQuestionPublic(
id: string,
): CancelablePromise<InterviewContentQuestionPublicDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-questions-public/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns InterviewContentQuestionPublicDto OK
     * @throws ApiError
     */
    public static updateInterviewContentQuestionPublic(
id: string,
requestBody: InterviewContentQuestionPublicCommand,
): CancelablePromise<InterviewContentQuestionPublicDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/interview-content-questions-public/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @returns InterviewContentQuestionPublicDto OK
     * @throws ApiError
     */
    public static deleteInterviewContentQuestionPublic(
id: string,
): CancelablePromise<InterviewContentQuestionPublicDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/interview-content-questions-public/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InterviewContentQuestionPublicDto Created
     * @throws ApiError
     */
    public static createInterviewContentQuestionPublic(
requestBody: InterviewContentQuestionPublicCommand,
): CancelablePromise<InterviewContentQuestionPublicDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/interview-content-questions-public',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param question 
     * @returns InterviewContentQuestionPublicDto OK
     * @throws ApiError
     */
    public static findAllByQuestion(
question: string,
): CancelablePromise<Array<InterviewContentQuestionPublicDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-questions-public/byQuestion',
            query: {
                'question': question,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns InterviewContentQuestionPublicDto OK
     * @throws ApiError
     */
    public static findAllInterviewContentQuestionPublic(): CancelablePromise<Array<InterviewContentQuestionPublicDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-questions-public/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
