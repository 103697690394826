import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {Formik, FormikValues, useFormik} from 'formik'
import {ID, isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialKnowledgeGroup} from '../core/_models'
import clsx from 'clsx'
import {FormattedMessage, useIntl} from "react-intl";
import {
    JobPositionCommand,
    JobPositionDto, JobPositionService, KnowledgeGroupDto
} from "../../../../../../services/requests";
import Select from "react-select";
import {IntlLabelizer} from "../../../../../../utils/IntlLabelizer";
import {useLang} from "../../../../../../_metronic/i18n/Metronici18n";
import {useToastData} from "../../../../../../utils/ToastNotificator";

type Props = {
    jobPosition?: JobPositionDto
    lineOfBusiness: ID
    knowledgeGroups: KnowledgeGroupDto[]
    refreshJobPositionId: Function
}

const editJobPositionSchema = Yup.object().shape({
    labelFr: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Name is required'),
    labelEn: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Name is required')
})
const MainInfos: FC<Props> = ({refreshJobPositionId, jobPosition, lineOfBusiness, knowledgeGroups}) => {

    const intl = useIntl()
    const lang = useLang()
    const {showSuccessMessage, showErrorMessage} = useToastData();



    const [jobPositionForEdit] = useState<JobPositionCommand>({
        ...jobPosition,
        labelFr: jobPosition?.labelFr || initialKnowledgeGroup.labelFr,
        labelEn: jobPosition?.labelEn || initialKnowledgeGroup.labelEn,
        lineOfBusiness: jobPosition?.lineOfBusiness || lineOfBusiness as string
    })


    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
        }
    }




    const submitForm = async (values, {setSubmitting}) => {

            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await JobPositionService.updateJobPosition(values.id as string, values)
                } else {
                    const res = await JobPositionService.createJobPosition(values)
                    refreshJobPositionId(res.id)

                }
                showSuccessMessage();
            } catch (ex) {
                showErrorMessage();
                console.error(ex)
            } finally {
                //setSubmitting(true)
                //cancel(true)
            }
    }

    return (
        <>
            <Formik
                initialValues={jobPositionForEdit} validationSchema={editJobPositionSchema} onSubmit={submitForm}
                >
                {props => (
            <form className='w-100' onSubmit={props.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.JOB_POSITION_EDIT.LABEL_FR'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('labelFr')}
                            type='text'
                            name='labelFr'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.labelFr && props.errors.labelFr},
                                {
                                    'is-valid': props.touched.labelFr && !props.errors.labelFr,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.labelFr && props.errors.labelFr && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.labelFr}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.JOB_POSITION_EDIT.LABEL_EN'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('labelEn')}
                            type='text'
                            name='labelEn'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.labelEn && props.errors.labelEn},
                                {
                                    'is-valid': props.touched.labelEn && !props.errors.labelEn,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.labelEn && props.errors.labelEn && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.labelEn}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    {!!knowledgeGroups && knowledgeGroups.length > 0 && (
                        <div className='fv-row mb-7'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.JOB_POSITION_EDIT.KNOWLEDGE_GROUPS'})}</label>

                            <Select
                                name='knowledgeGroups'
                                options={knowledgeGroups.map(knowledgeGroup => {
                                    return {
                                        value: knowledgeGroup.id,
                                        label: IntlLabelizer.getIntlLabel(knowledgeGroup, lang)
                                    };})}
                                isMulti
                                onChange={(e) => (
                                    props.values.knowledgeGroups = e.map((elt) => elt.value!)
                                )}
                                defaultValue={jobPosition?.knowledgeGroups?.map(knowledgeGroupId => {
                                    return {
                                        value: knowledgeGroupId,
                                        label: IntlLabelizer.getIntlLabel(knowledgeGroups.find(tw => tw.id == knowledgeGroupId), lang)
                                    };})}
                            />
                            {/* end::Label */}
                        </div>
                    )}
                    {/* end::Input group */}


                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={props.isSubmitting || !props.isValid || !props.touched}
                    >
                        {(!props.isSubmitting) && (
                            <span className='indicator-label'>{intl.formatMessage({id: 'FORM.ACTION.SAVE'})}</span>
                        )}
                        {(props.isSubmitting) && (
                            <span>
                                {intl.formatMessage({id: 'COMMON.PLEASE_WAIT'})}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            )}
            </Formik>



        </>
    )
}

export {MainInfos}
