import {ListViewProvider, useListView} from './core/ListViewProvider'
import {KTCard} from '../../../../../_metronic/helpers'
import {useState} from "react";
import {TechnicalWordsListHeader} from "./components/TechnicalWordsListHeader";
import {QueryRequestProvider} from "./core/QueryRequestProvider";
import {QueryResponseProvider} from "./core/QueryResponseProvider";
import {TechnicalWordsTable} from "./table/TechnicalWordsTable";

const TechnicalWordsListPage = () => {
  const {itemIdForUpdate} = useListView()
  const [currentLineOfBusiness, setCurrentLineOfBusiness] = useState('');



  return (
    <>
      <KTCard className='mt-5'>
        <TechnicalWordsListHeader />
        <TechnicalWordsTable />
      </KTCard>
    </>
  )
}

const TechnicalWordsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TechnicalWordsListPage />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TechnicalWordsListWrapper}
