/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InterviewContentThematicCommand } from '../models/InterviewContentThematicCommand';
import type { InterviewContentThematicDto } from '../models/InterviewContentThematicDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InterviewContentThematicService {

    /**
     * @param id 
     * @returns InterviewContentThematicDto OK
     * @throws ApiError
     */
    public static findByIdInterviewContentThematic(
id: string,
): CancelablePromise<InterviewContentThematicDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-thematics/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns InterviewContentThematicDto OK
     * @throws ApiError
     */
    public static updateInterviewContentThematic(
id: string,
requestBody: InterviewContentThematicCommand,
): CancelablePromise<InterviewContentThematicDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/interview-content-thematics/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InterviewContentThematicDto Created
     * @throws ApiError
     */
    public static createInterviewContentThematic(
requestBody: InterviewContentThematicCommand,
): CancelablePromise<InterviewContentThematicDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/interview-content-thematics',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param lineOfBusiness 
     * @returns InterviewContentThematicDto OK
     * @throws ApiError
     */
    public static findAllByLineOfBusinessInterviewContentThematic(
lineOfBusiness: string,
): CancelablePromise<Array<InterviewContentThematicDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-thematics/byLineOfBusiness',
            query: {
                'lineOfBusiness': lineOfBusiness,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns InterviewContentThematicDto OK
     * @throws ApiError
     */
    public static findAllInterviewContentThematic(): CancelablePromise<Array<InterviewContentThematicDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-thematics/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
