import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {Formik, FormikValues, useFormik} from 'formik'
import {ID, isNotEmpty} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {
    LineOfBusinessCommand,
    LineOfBusinessDto, LineOfBusinessService,
} from "../../../../../services/requests";
import {useIntl} from "react-intl";
import {useLang} from "../../../../../_metronic/i18n/Metronici18n";
import {useToastData} from "../../../../../utils/ToastNotificator";

type Props = {
    lineOfBusiness?: LineOfBusinessDto,
    refreshLineOfBusinessId: Function
    refreshLineOfBusiness: Function
}

const editLineOfBusinessSchema = Yup.object().shape({
    labelFr: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 50 symbols')
        .required('Name is required'),
    labelEn: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 50 symbols')
        .required('Name is required'),
    leadName: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 50 symbols')
        .required('leaderName is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 50 symbols'),
})
const MainInfos: FC<Props> = ({refreshLineOfBusinessId, refreshLineOfBusiness, lineOfBusiness}) => {

    const intl = useIntl()
    const {showSuccessMessage, showErrorMessage} = useToastData();


    const [lineOfBusinessForEdit] = useState<LineOfBusinessCommand>({
        ...lineOfBusiness,
        labelFr: lineOfBusiness?.labelFr as string as string,
        labelEn: lineOfBusiness?.labelEn as string,
        leadName: lineOfBusiness?.leadName as string,
        email: lineOfBusiness?.email as string || "",
    })

    const submitForm = async (values, {setSubmitting}) => {

            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await LineOfBusinessService.updateLineOfBusiness(values.id as string, values);
                    refreshLineOfBusiness();
                } else {
                    const res = await LineOfBusinessService.createLineOfBusiness(values)
                    refreshLineOfBusinessId(res.id)

                }
                showSuccessMessage();
            } catch (ex) {
                showErrorMessage();
                console.error(ex)
            } finally {
            }
    }

    return (
        <>
            <Formik
                initialValues={lineOfBusinessForEdit} validationSchema={editLineOfBusinessSchema} onSubmit={submitForm}
                >
                {props => (
            <form className='w-100' onSubmit={props.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                >

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.LINE_OF_BUSINESS_EDIT.LABEL_FR'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('labelFr')}
                            type='text'
                            name='labelFr'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.labelFr && props.errors.labelFr},
                                {
                                    'is-valid': props.touched.labelFr && !props.errors.labelFr,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.labelFr && props.errors.labelFr && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.labelFr}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.LINE_OF_BUSINESS_EDIT.LABEL_EN'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('labelEn')}
                            type='text'
                            name='labelEn'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.labelEn && props.errors.labelEn},
                                {
                                    'is-valid': props.touched.labelEn && !props.errors.labelEn,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.labelEn && props.errors.labelEn && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.labelEn}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.LINE_OF_BUSINESS_EDIT.LEAD_NAME'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('leadName')}
                            type='text'
                            name='leadName'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.leadName && props.errors.leadName},
                                {
                                    'is-valid': props.touched.leadName && !props.errors.leadName,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.leadName && props.errors.leadName && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.leadName}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.LINE_OF_BUSINESS_EDIT.EMAIL'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('email')}
                            type='email'
                            name='email'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.email && props.errors.email},
                                {
                                    'is-valid': props.touched.email && !props.errors.email,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.email && props.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.email}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}


                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={props.isSubmitting || !props.isValid || !props.touched}
                    >
                        {(!props.isSubmitting) && (
                            <span className='indicator-label'>{intl.formatMessage({id: 'FORM.ACTION.SAVE'})}</span>
                        )}
                        {(props.isSubmitting) && (
                            <span>
                                {intl.formatMessage({id: 'COMMON.PLEASE_WAIT'})}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            )}
            </Formik>



        </>
    )
}

export {MainInfos}
