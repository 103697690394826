import {useListView} from '../core/ListViewProvider'
import {GroupsListToolbar} from './GroupsListToolbar'
import {LineOfBusinessFilter} from "./LineOfBusinessFilter";

const GroupsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
        <LineOfBusinessFilter />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <GroupsListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {GroupsListHeader}
