/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, isNotEmpty, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {JobPositionService, KnowledgeGroupService, TechnicalWordService} from "../../../../../../../services/requests";
import {useToastData} from "../../../../../../../utils/ToastNotificator";
import { confirmAlert } from 'react-confirm-alert';
import {useIntl} from "react-intl";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useAuth} from "../../../../../../modules/auth"; // Import css

type Props = {
  id: ID
  label: string
}

const GroupActionsCell: FC<Props> = ({id, label}) => {
  const {showSuccessMessage, showErrorMessage} = useToastData();
  const {currentUser} = useAuth()
  const intl = useIntl();

  const isUserAdmin = !!currentUser?.roles?.find(role => role == "ADMIN");

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItem = () => {

    confirmAlert({
      title: intl.formatMessage({id: 'FORM.ACTION.CONFIRM_NEEDED'}),
      message: intl.formatMessage({id: 'FORM.ACTION.CONFIRM_ACTION'}, {element: label}),
      buttons: [
        {
          label: intl.formatMessage({id: 'COMMON.YES'}),
          onClick: () => processDeleteItem()
        },
        {
          label: intl.formatMessage({id: 'COMMON.NO'})
        }
      ]
    });
  }

  const processDeleteItem = async () => {

    try {
      await TechnicalWordService.deleteTechnicalWord(id as string);
      showSuccessMessage();
      window.location.reload();
    }
    catch(ex) {
        showErrorMessage();
        console.error(ex);

    }
  }

  return (
    <>
    {isUserAdmin && (
      <div className='menu-item px-3'>
        <a className='menu-link px-3' onClick={deleteItem}>
          <i className='bi bi-trash text-primary px-3'></i>
        </a>
      </div>
    )}
    </>
  )
}

export {GroupActionsCell}
