/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KnowledgeGroupCommand } from '../models/KnowledgeGroupCommand';
import type { KnowledgeGroupDto } from '../models/KnowledgeGroupDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class KnowledgeGroupService {

    /**
     * @param id 
     * @returns KnowledgeGroupDto OK
     * @throws ApiError
     */
    public static findByIdKnowledgeGroup(
id: string,
): CancelablePromise<KnowledgeGroupDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns KnowledgeGroupDto OK
     * @throws ApiError
     */
    public static updateKnowledgeGroup(
id: string,
requestBody: KnowledgeGroupCommand,
): CancelablePromise<KnowledgeGroupDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/knowledge-groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @returns KnowledgeGroupDto OK
     * @throws ApiError
     */
    public static deleteKnowledgeGroup(
id: string,
): CancelablePromise<KnowledgeGroupDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/knowledge-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns KnowledgeGroupDto Created
     * @throws ApiError
     */
    public static createKnowledgeGroup(
requestBody: KnowledgeGroupCommand,
): CancelablePromise<KnowledgeGroupDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/knowledge-groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param lineOfBusiness 
     * @returns KnowledgeGroupDto OK
     * @throws ApiError
     */
    public static findAllByLineOfBusinessKnowledgeGroup(
lineOfBusiness: string,
): CancelablePromise<Array<KnowledgeGroupDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/byLineOfBusiness',
            query: {
                'lineOfBusiness': lineOfBusiness,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns KnowledgeGroupDto OK
     * @throws ApiError
     */
    public static findAllKnowledgeGroup(): CancelablePromise<Array<KnowledgeGroupDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
