import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery, useQueryClient} from "react-query";
import {
    JobPositionService, KnowledgeGroupService, LineOfBusinessService, TechnicalWordService, UserService
} from "../../../../services/requests";
import {useLocation} from "react-router";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";


type Params = {
  userId?: ID
}

const UserEditPage: FC = () => {
  const location = useLocation();
  const locationState = location.state as Params;
  const intl = useIntl()
    const queryClient = useQueryClient();

    const [userId, setUserId] = useState(locationState.userId);

    const {
        isLoading: isUserLoading,
        data: user,
    } = useQuery({
            queryKey: ['user', userId],
            queryFn: () => {
                return UserService.findByIdUser(userId as string);
            },
            enabled: !!userId
        }
    )

    const { data: lineOfBusinesses, isLoading: isLineOfBusinessesLoading } = useQuery({
        queryKey: ['lineOfBusinesses'],
        queryFn: () => {
            // Do something here
            return LineOfBusinessService.findAllLineOfBusiness();
        }
    });

    const { data: availableRoles, isLoading: isRolesLoading } = useQuery({
        queryKey: ['availableRoles'],
        queryFn: () => {
            // Do something here
            return UserService.getAllAvailableUserRoles();
        }
    });

    const everythingLoaded = (!userId || user) && !!lineOfBusinesses && !!availableRoles;

    const pageTitle = user ?
        intl.formatMessage({id: 'PAGE.USER.TITLE.UPDATE'}, {name: user?.name})
        : intl.formatMessage({id: 'PAGE.USER.TITLE.NEW'});

    const refreshUserId = (userId: string) => {
        setUserId(userId);
        queryClient.removeQueries({ queryKey: ['user', userId] });
        queryClient.removeQueries({ queryKey: ['users'] });
    }

    const breadCrumb: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'PAGE.USERS.TITLE'}),
            path: '/admin/users',
            isSeparator: false,
            isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

  return (
      <>
        <PageTitle breadcrumbs={breadCrumb}>
            {pageTitle}
        </PageTitle>
        <div className='card mb-5'>
      <div className='card-body'>
            {everythingLoaded && (
            <div className='mx-auto mw-600px w-100 pt-5'>
                <MainInfos refreshUserId={refreshUserId} user={user} lineOfBusinesses={lineOfBusinesses} availableRoles={availableRoles} />
            </div>
            )}
        </div>
      </div>
    </>

  )
}

export {UserEditPage}
