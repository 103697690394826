import {useListView} from '../core/ListViewProvider'
import {TechnicalWordsListToolbar} from './TechnicalWordsListToolbar'
import {GroupsFilter} from "./GroupsFilter";
import {TechnicalWordsSearchComponent} from "./TechnicalWordsSearchComponent";

const TechnicalWordsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
        <GroupsFilter />
        <TechnicalWordsSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <TechnicalWordsListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TechnicalWordsListHeader}
