import React, {FC} from "react";
import {
    GetTechnicalAdvisorsForInterview,
    KnowledgeGroupDto, KnowledgeGroupLineOfBusinessDto, KnowledgeGroupLineOfBusinessService,
    LineOfBusinessDto
} from "../../../../../../services/requests";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import {useLang} from "../../../../../../_metronic/i18n/Metronici18n";
import {IntlLabelizer} from "../../../../../../utils/IntlLabelizer";
import {useToastData} from "../../../../../../utils/ToastNotificator";
import {useQueryClient} from "react-query";

type Props = {
    knowledgeGroup: KnowledgeGroupDto
    lineOfBusinesses: LineOfBusinessDto[]
    associatedLineOfBusinesses: KnowledgeGroupLineOfBusinessDto[]
}

const editAssociatedLobSchema = Yup.object().shape({
    associatedLineOfBusinesses: Yup.array()
        .min(1)
})

type LineOfBusinesses = {
    jobPosition: string
    levelOfExpertise: string[]
}
const LineOfBusinesses: FC<Props> = ({knowledgeGroup, lineOfBusinesses, associatedLineOfBusinesses}) => {

    const intl = useIntl()
    const lang = useLang()
    const {showSuccessMessage, showErrorMessage} = useToastData();
    const lineOfBusinessesForForm: string[] = [];
    const queryClient = useQueryClient();
    if(Array.isArray(associatedLineOfBusinesses)) {
        associatedLineOfBusinesses?.forEach(alob => {
            lineOfBusinessesForForm[lineOfBusinessesForForm.length] = alob.lineOfBusiness;
        });
    }

    const formikGroupLob = useFormik({
        initialValues: {associatedLineOfBusinesses: lineOfBusinessesForForm},
        validationSchema: editAssociatedLobSchema,
        onSubmit: async (values) => {

            lineOfBusinesses?.map(async lineOfBusiness => {
                try {

                    const currentKey = values.associatedLineOfBusinesses.find(elt => elt == lineOfBusiness.id);
                    const activeLobToWorkOn = associatedLineOfBusinesses?.find(elt => elt.lineOfBusiness === lineOfBusiness.id);
                    if (!!currentKey) {
                        if (!activeLobToWorkOn) {
                            await KnowledgeGroupLineOfBusinessService.createKnowledgeGroupLineOfBusiness({
                                knowledgeGroup: knowledgeGroup.id as string,
                                lineOfBusiness: lineOfBusiness.id as string
                            });
                        }
                    } else {
                        if (!!activeLobToWorkOn) {
                            await KnowledgeGroupLineOfBusinessService.deleteKnowledgeGroupLineOfBusiness(activeLobToWorkOn.id);
                        }
                    }
                    showSuccessMessage();
                    queryClient.removeQueries({ queryKey: ['knowledgGrouplineOfBusinesses', knowledgeGroup.id] });
                } catch (ex) {
                    showErrorMessage();
                    console.log(ex);
                }


            });
        },
    })

    return (
        <>
            {!!lineOfBusinesses && lineOfBusinesses.length > 0 && (
                <form id='technical-advisor-acceptance-skills' className='form' onSubmit={formikGroupLob.handleSubmit} noValidate>
                    {lineOfBusinesses.map(lineOfBusiness => (
                        <div className='fv-row mb-15'>
                            <div>
                                <label className='form-check form-check-inline form-check-solid me-5'>
                                    <input
                                        {...formikGroupLob.getFieldProps('associatedLineOfBusinesses')}
                                        defaultChecked={!!associatedLineOfBusinesses.find(as => as.lineOfBusiness == lineOfBusiness.id)}
                                        className='form-check-input'
                                        name='associatedLineOfBusinesses'
                                        type='checkbox'
                                        value={lineOfBusiness.id}
                                    />
                                    <span className='fw-bold ps-2 fs-6'>{IntlLabelizer.getIntlLabel(lineOfBusiness, lang)}</span>
                                </label>
                            </div>
                        </div>
                    ))}
                    {formikGroupLob.touched.associatedLineOfBusinesses && formikGroupLob.errors.associatedLineOfBusinesses && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formikGroupLob.errors.associatedLineOfBusinesses}</span>
                            </div>
                        </div>
                    )}
                    <div className='text-center pt-15'>
                        <button
                            type='submit'
                            className='btn btn-primary me-3'
                        >
                            {(!formikGroupLob.isSubmitting) && (
                                <span className='indicator-label'>{intl.formatMessage({id: 'FORM.ACTION.SAVE'})}</span>
                            )}
                            {(formikGroupLob.isSubmitting) && (
                                <span>
                                {intl.formatMessage({id: 'COMMON.PLEASE_WAIT'})}{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            )}
                        </button>
                    </div>
                </form>
            )}
        </>
    );
}


export {LineOfBusinesses};