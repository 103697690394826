import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../../_metronic/helpers'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery, useQueryClient} from "react-query";
import {
    JobPositionService, KnowledgeGroupService, TechnicalWordService
} from "../../../../../services/requests";
import {useLocation} from "react-router";
import {PageLink, PageTitle} from "../../../../../_metronic/layout/core";
import {useLang} from "../../../../../_metronic/i18n/Metronici18n";
import {IntlLabelizer} from "../../../../../utils/IntlLabelizer";


type Params = {
  jobPositionId?: ID
  lineOfBusiness: ID
}

const JobPositionEditPage: FC = () => {
    const location = useLocation();
    const locationState = location.state as Params;
    const intl = useIntl()
    const lang = useLang()
    const queryClient = useQueryClient();

    const [jobPositionId, setJobPositionId] = useState(locationState.jobPositionId);
    const [lineOfBusiness] = useState(locationState.lineOfBusiness);

    const {
        isLoading: isJobPositionLoading,
        data: jobPosition,
    } = useQuery({
            queryKey: ['jobPosition', jobPositionId],
            queryFn: () => {
                return JobPositionService.findByIdJobPosition(jobPositionId as string);
            },
            enabled: !!jobPositionId
        }
    )

    const { data: knowledgeGroups, isLoading: isKnowledgeGroupsLoading } = useQuery({
        queryKey: ['knowledgeGroups', lineOfBusiness],
        queryFn: () => {
            // Do something here
            return KnowledgeGroupService.findAllByLineOfBusinessKnowledgeGroup(lineOfBusiness as string);
        }
    });

    const everythingLoaded = (!jobPositionId || jobPosition) && knowledgeGroups ;

    const pageTitle = jobPosition ?
        intl.formatMessage({id: 'PAGE.JOB_POSITION.TITLE.UPDATE'}, {name: IntlLabelizer.getIntlLabel(jobPosition, lang)})
        : intl.formatMessage({id: 'PAGE.JOB_POSITION.TITLE.NEW'});

    const refreshJobPositionId = (newJobPositionId: string) => {
        setJobPositionId(newJobPositionId);
        queryClient.removeQueries({ queryKey: ['jobPosition', newJobPositionId] });
        queryClient.removeQueries({ queryKey: ['jobPositions'] });
    }

    const breadCrumb: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'PAGE.JOB_POSITIONS.TITLE'}),
            path: '/admin/line-of-businesses/job-positions',
            isSeparator: false,
            isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

  return (
      <>
        <PageTitle breadcrumbs={breadCrumb}>
            {pageTitle}
        </PageTitle>
        <div className='card mb-5'>
      <div className='card-body'>
            {everythingLoaded && (
            <div className='mx-auto mw-600px w-100 pt-5'>
                <MainInfos knowledgeGroups={knowledgeGroups} lineOfBusiness={lineOfBusiness} refreshJobPositionId={refreshJobPositionId} jobPosition={jobPosition} />
            </div>
            )}
        </div>
      </div>
    </>

  )
}

export {JobPositionEditPage}
