import {ListViewProvider, useListView} from './core/ListViewProvider'
import {KTCard} from '../../../../../_metronic/helpers'
import {useState} from "react";
import {GroupsListHeader} from "./components/GroupsListHeader";
import {QueryRequestProvider} from "./core/QueryRequestProvider";
import {QueryResponseProvider} from "./core/QueryResponseProvider";
import {GroupsTable} from "./table/GroupsTable";

const GroupsListPage = () => {
  const {itemIdForUpdate} = useListView()
  const [currentLineOfBusiness, setCurrentLineOfBusiness] = useState('');



  return (
    <>
      <KTCard className='mt-5'>
        <GroupsListHeader />
        <GroupsTable />
      </KTCard>
    </>
  )
}

const GroupsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <GroupsListPage />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {GroupsListWrapper}
