/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";
import {useLang} from "../../../i18n/Metronici18n";
import {IntlLabelizer} from "../../../../utils/IntlLabelizer";

export function AsideMenuMain() {

  const intl = useIntl()
    const {currentUser} = useAuth()
  return (
    <>
        {currentUser?.roles?.find(role => role == "RECRUITER") && (
            <>
              <AsideMenuItem
                to='/search-for-interview'
                title={intl.formatMessage({id: 'MENU.SEARCH'})}
                fontIcon='bi-search fs-2'
                bsTitle={intl.formatMessage({id: 'MENU.SEARCH'})}
                className='py-2'
              />
            </>
        )}

      {currentUser?.roles?.find(role => role == "TECH_ADVISOR") && (
          <>
            <AsideMenuItem
                to='/technical-advisors/self'
                title={intl.formatMessage({id: 'MENU.EDIT_TECHNICAL_ADVISOR_SELF_INFOS'})}
                fontIcon='bi-tools fs-2'
                bsTitle={intl.formatMessage({id: 'MENU.EDIT_TECHNICAL_ADVISOR_SELF_INFOS'})}
                className='py-2'
            />
          </>
      )}


      <AsideMenuItemWithSubMain
          to='/admin/line-of-businesses'
          title={intl.formatMessage({id: 'MENU.LINE_OF_BUSINESSES'})}
          bsTitle={intl.formatMessage({id: 'MENU.LINE_OF_BUSINESSES'})}
          fontIcon='bi-building-gear fs-2'

      >
        <AsideMenuItem
            to='/admin/line-of-businesses'
            title={intl.formatMessage({id: 'MENU.LINE_OF_BUSINESSES'})}
            fontIcon='bi-building fs-2'
        />
        {currentUser?.roles?.find(role => role == "LOB_ADMIN") && (
            <>
              <AsideMenuItem
                  to='/admin/line-of-businesses/job-positions'
                  title={intl.formatMessage({id: 'MENU.JOB_POSITIONS'})}
                  fontIcon='bi-door-open fs-2'
              />

              <AsideMenuItem
                  to='/admin/technical-advisors'
                  title={intl.formatMessage({id: 'MENU.TECHNICAL_ADVISORS'})}
                  fontIcon='bi-people fs-2'
                  bsTitle={intl.formatMessage({id: 'MENU.TECHNICAL_ADVISORS'})}
                  className='py-2 text-center'
              />


            </>
        )}
        </AsideMenuItemWithSubMain>

      {currentUser?.roles?.find(role => role == "LOB_ADMIN") && (
          <>

            <AsideMenuItemWithSubMain
                to='/admin/knowledges/groups/list'
                title={intl.formatMessage({id: 'MENU.KNOWLEDGES'})}
                bsTitle={intl.formatMessage({id: 'MENU.KNOWLEDGES'})}
                fontIcon='bi-stack fs-2'
            >
            <AsideMenuItem
                to='/admin/knowledges/groups/list'
                title={intl.formatMessage({id: 'MENU.KNOWLEDGES.GROUPS'})}
                fontIcon='bi-chat fs-2'
            />
            <AsideMenuItem
                to='/admin/knowledges/technical-words/list'
                title={intl.formatMessage({id: 'MENU.KNOWLEDGES.WORDS'})}
                fontIcon='bi-chat-dots fs-2'
            />
            </AsideMenuItemWithSubMain>
          </>
      )}
      {currentUser?.roles?.find(role => role == "ADMIN") && (
          <>
            <AsideMenuItem
                to='/admin/users'
                title={intl.formatMessage({id: 'MENU.USERS'})}
                fontIcon='bi-person-circle fs-2'
                bsTitle={intl.formatMessage({id: 'MENU.USERS'})}
                className='py-2 text-center'
            />
          </>
      )}

        {/*{currentUser?.roles?.find(role => role == "ROLE_ICC") && (*/}
        {/*    <>*/}
        {/*        <AsideMenuItem*/}
        {/*            to='/admin/interviews-content'*/}
        {/*            title={intl.formatMessage({id: 'MENU.INTERVIEWS_CONTENT'})}*/}
        {/*            fontIcon='bi-patch-question fs-2'*/}
        {/*            bsTitle={intl.formatMessage({id: 'MENU.INTERVIEWS_CONTENT'})}*/}
        {/*            className='py-2 text-center'*/}
        {/*        />*/}
        {/*    </>*/}
        {/*)}*/}

        {/*{currentUser?.roles?.find(role => role == "ROLE_TA") && (*/}
        {/*    <>*/}
        {/*    <AsideMenuItem*/}
        {/*        to='/interview-content-questions'*/}
        {/*        title={intl.formatMessage({id: 'MENU.INTERVIEWS_CONTENT_QUESTIONS'})}*/}
        {/*        fontIcon='bi-chat-dots fs-2'*/}
        {/*        bsTitle={intl.formatMessage({id: 'MENU.INTERVIEWS_CONTENT_QUESTIONS'})}*/}
        {/*        className='py-2 text-center'*/}
        {/*    />*/}
        {/*    </>*/}
        {/*)}*/}

    </>
  )
}
