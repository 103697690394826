import React, {FC, useState} from "react";
import {
    JobPositionCommand,
    JobPositionService,
    KnowledgeGroupDto,
    KnowledgeGroupTechnicalWordDto, KnowledgeGroupTechnicalWordService,
    TechnicalWordDto
} from "../../../../../../services/requests";
import {Formik, useFormik} from "formik";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import Select from "react-select";
import {isNotEmpty} from "../../../../../../_metronic/helpers";
import {initialKnowledgeGroup} from "../../../../line-of-businesses/job-positions/edit/core/_models";
import {IntlLabelizer} from "../../../../../../utils/IntlLabelizer";
import {useLang} from "../../../../../../_metronic/i18n/Metronici18n";
import {useToastData} from "../../../../../../utils/ToastNotificator";
import {useQueryClient} from "react-query";

type Props = {
    technicalWord: TechnicalWordDto
    groups: KnowledgeGroupDto[]
    associatedGroups: KnowledgeGroupTechnicalWordDto[]
}

const editAssociatedGroupsSchema = Yup.object().shape({
    associatedGroups: Yup.array()
        .min(1)
})

type KnowledgeGroups = {
    jobPosition: string
    levelOfExpertise: string[]
}

type EditFormGroup = {
    associatedGroupsInput: string[]
}
const KnowledgeGroups: FC<Props> = ({technicalWord, groups, associatedGroups}) => {

    const intl = useIntl()
    const lang = useLang()
    const {showSuccessMessage, showErrorMessage} = useToastData();
    const groupsForForm: string[] = [];
    const queryClient = useQueryClient();
    if(Array.isArray(associatedGroups)) {
        associatedGroups?.forEach(alob => {
            groupsForForm[groupsForForm.length] = alob.knowledgeGroup;
        });
    }


    const [groupsForEdit] = useState<EditFormGroup>({
        associatedGroupsInput: []
    })
    const submitForm = async (values, {setSubmitting}) => {

        setSubmitting(true);
        groups?.map(async group => {
            try {
                const currentKey = values.associatedGroupsInput.find(elt => elt == group.id);
                const activeGroupToWorkOn = associatedGroups?.find(elt => elt.knowledgeGroup === group.id);
                if(!!currentKey) {
                    if(!activeGroupToWorkOn)  {
                        await KnowledgeGroupTechnicalWordService.createKnowledgeGroupTechnicalWord({
                            technicalWord: technicalWord.id as string,
                            knowledgeGroup: group.id as string
                        });
                    }
                } else {
                    if(!!activeGroupToWorkOn) {
                        await KnowledgeGroupTechnicalWordService.deleteKnowledgeGroupTechnicalWord(activeGroupToWorkOn.id);
                    }
                }
                showSuccessMessage();
                queryClient.removeQueries({ queryKey: ['knowledgeGroupsTechnicalWord', technicalWord.id] });
            } catch (ex) {
                showErrorMessage();
                console.log(ex);
            }

        });
    }

    return (
        <>

            {!!groups && groups.length > 0 && (
                <Formik
                    initialValues={groupsForEdit} validationSchema={editAssociatedGroupsSchema} onSubmit={submitForm}
                >
                    {props => (
                        <form className='w-100' onSubmit={props.handleSubmit} noValidate>
                    {/* begin::Input group */}
                        <div className='fv-row mb-7'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.JOB_POSITION_EDIT.KNOWLEDGE_GROUPS'})}</label>

                            <Select
                                name='groups'
                                options={groups.map(knowledgeGroup => {
                                    return {
                                        value: knowledgeGroup.id,
                                        label: IntlLabelizer.getIntlLabel(knowledgeGroup, lang)
                                    };})}
                                isMulti
                                onChange={(e) => (
                                    props.values.associatedGroupsInput = e.map((elt) => elt.value!)
                                )}
                                defaultValue={associatedGroups?.map(kgtw => {
                                    return {
                                        value: kgtw.knowledgeGroup,
                                        label: IntlLabelizer.getIntlLabel(groups.find(tw => tw.id == kgtw.knowledgeGroup), lang)
                                    };})}
                            />
                            {/* end::Label */}
                        </div>
                    {/* end::Input group */}
                    <div className='text-center pt-15'>
                        <button
                            type='submit'
                            className='btn btn-primary me-3'
                        >
                            {(!props.isSubmitting) && (
                                <span className='indicator-label'>{intl.formatMessage({id: 'FORM.ACTION.SAVE'})}</span>
                            )}
                            {(props.isSubmitting) && (
                                <span>
                                {intl.formatMessage({id: 'COMMON.PLEASE_WAIT'})}{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            )}
                        </button>
                    </div>
                </form>
                )}
            </Formik>
            )}
        </>
    );
}


export {KnowledgeGroups};