import React, {FC, useState} from "react";
import {ID, initialQueryState} from "../../../../../_metronic/helpers";
import {useQuery, useQueryClient} from "react-query";
import {
    GetTechnicalAdvisorsForInterview,
    JobPositionDto,
    JobPositionService,
    LineOfBusinessDto,
    TechnicalAdvisorAcceptanceSkillDto,
    TechnicalAdvisorDto,
    TechnicalAdvisorSAcceptanceSkillService
} from "../../../../../services/requests";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import {useLang} from "../../../../../_metronic/i18n/Metronici18n";
import {IntlLabelizer} from "../../../../../utils/IntlLabelizer";
import Select from "react-select";
import {useToastData} from "../../../../../utils/ToastNotificator";

type Props = {
    technicalAdvisorId: string
    jobPositions: JobPositionDto[]
    activeAcceptanceSkills: TechnicalAdvisorAcceptanceSkillDto[]
    reloadAcceptanceSkills: Function
}

const editAcceptanceSkillsSchema = Yup.object().shape({
    acceptanceSkills: Yup.array()
        .min(1)
})

type AcceptanceSkills = {
    jobPosition: string
    levelOfExpertise: string[]
}
const AcceptanceSkills: FC<Props> = ({technicalAdvisorId, activeAcceptanceSkills, jobPositions, reloadAcceptanceSkills}) => {

    const intl = useIntl();
    const lang = useLang();
    const [visibleJobPositions, setVisibleJobPositions] = useState<string[]>(activeAcceptanceSkills.map(elt => elt.jobPosition));

    const {showSuccessMessage, showErrorMessage} = useToastData();
    const queryClient = useQueryClient();
    const acceptanceSkillsForForm: string[] = [];
    if(Array.isArray(activeAcceptanceSkills)) {
        activeAcceptanceSkills?.forEach(as => {
            const jP = as.jobPosition;
            as.levelsOfExpertise?.forEach(loe => acceptanceSkillsForForm[acceptanceSkillsForForm.length] = jP + '/' + loe);
        });
    }



    const formikAcceptanceSkills = useFormik({
        initialValues: {acceptanceSkills: acceptanceSkillsForForm},
        validationSchema: editAcceptanceSkillsSchema,
        onSubmit: async (values, {setSubmitting}) => {

            let newAcceptanceSkills = values.acceptanceSkills.map(as => {
                let asSplit = as.split("/");
                return {id: asSplit[0], levelOfExpertise: asSplit[1] }
            }).reduce((list, { id, levelOfExpertise }) => {
                (list[id] = list[id] || []).push(levelOfExpertise);
                return list;
            }, {});

            let newAcceptance : TechnicalAdvisorAcceptanceSkillDto[] = [];
            jobPositions?.map(async jobPosition => {
                try {
                    const currentKey = Object.keys(newAcceptanceSkills).find(elt => elt == jobPosition.id);
                    const activeAcceptanceSkillToWorkOn = activeAcceptanceSkills?.find(elt => elt.jobPosition === jobPosition.id);
                    if(!!currentKey) {
                        if(!!activeAcceptanceSkillToWorkOn) {

                            let updateNeeded = activeAcceptanceSkillToWorkOn.levelsOfExpertise.length != newAcceptanceSkills[currentKey].length;
                            if(!updateNeeded) {
                                updateNeeded = activeAcceptanceSkillToWorkOn.levelsOfExpertise.filter(loe => !newAcceptanceSkills[currentKey].find(newLoe => newLoe == loe)).length > 0
                                    || newAcceptanceSkills[currentKey].filter(loe => !activeAcceptanceSkillToWorkOn.levelsOfExpertise.find(newLoe => newLoe == loe)).length > 0
                            }
                            if(updateNeeded) {
                                let res = await TechnicalAdvisorSAcceptanceSkillService.updateTechnicalAdvisorAcceptanceSkill(activeAcceptanceSkillToWorkOn.id, {
                                    id: activeAcceptanceSkillToWorkOn.id,
                                    technicalAdvisor: technicalAdvisorId,
                                    jobPosition: jobPosition.id as string,
                                    levelsOfExpertise: newAcceptanceSkills[currentKey]
                                });
                                newAcceptance[newAcceptance.length] = res;
                            } else {
                                newAcceptance[newAcceptance.length] = activeAcceptanceSkillToWorkOn;
                            }
                        } else {
                            let res = await TechnicalAdvisorSAcceptanceSkillService.createTechnicalAdvisorAcceptanceSkill({
                                technicalAdvisor: technicalAdvisorId,
                                jobPosition: jobPosition.id as string,
                                levelsOfExpertise: newAcceptanceSkills[currentKey]
                            });
                            newAcceptance[newAcceptance.length] = res;
                        }
                    } else {
                        if(!!activeAcceptanceSkillToWorkOn) {
                            await TechnicalAdvisorSAcceptanceSkillService.deleteTechnicalAdvisorAcceptanceSkill(activeAcceptanceSkillToWorkOn.id);
                        }
                    }
                    showSuccessMessage();
                    queryClient.removeQueries({ queryKey: ['acceptanceSkills', technicalAdvisorId] });
                } catch (ex) {
                    showErrorMessage();
                    console.log(ex);
                }
                reloadAcceptanceSkills()

            });
        },
    })

    const changeAcceptanceSkillsVisibility = (e) => {
        setVisibleJobPositions(e.map(e => e.value));
    }

    return (
        <>
            {!!jobPositions && jobPositions.length > 0 && (
                <form id='technical-advisor-acceptance-skills' className='form' onSubmit={formikAcceptanceSkills.handleSubmit} noValidate>
                    {/* begin::Input group */}
                    <div className='fv-row mb-12'>
                        {/* begin::Label */}
                        <Select
                            name='jobPositions'
                            className='min-w-600px'
                            options={jobPositions.map(jp => {
                                return {
                                    value: jp.id,
                                    label: IntlLabelizer.getIntlLabel(jp, lang)
                                };})}
                            isMulti
                            onChange={changeAcceptanceSkillsVisibility}
                            defaultValue={activeAcceptanceSkills?.map(as => {
                                return {
                                    value: as.jobPosition,
                                    label: IntlLabelizer.getIntlLabel(jobPositions.find(tw => tw.id == as.jobPosition), lang)
                                };})}
                        />
                        {/* end::Label */}
                    </div>
                    {/* end::Input group */}

                    {jobPositions.map(jobPosition => (
                        <div
                            id={"container-as-" + jobPosition.id}
                            className={!!visibleJobPositions?.find(vJp => vJp == jobPosition.id)?"fv-row mb-15":'fv-row mb-15 d-none'}
                        >
                            <label className='fw-bold fs-6 mb-2'>{IntlLabelizer.getIntlLabel(jobPosition, lang)}</label>

                            <div>
                                {Object.keys(GetTechnicalAdvisorsForInterview.levelOfExpertise)?.map((levelOfExpertise) => (
                                    <label
                                        id={"label-" + jobPosition.id + '' + levelOfExpertise}
                                        className='form-check form-check-inline form-check-solid me-5'>
                                        <input
                                            {...formikAcceptanceSkills.getFieldProps('acceptanceSkills')}
                                            defaultChecked={!!acceptanceSkillsForForm.find(as => as == (jobPosition.id + '/' + levelOfExpertise))}
                                            className='form-check-input'
                                            name='acceptanceSkills'
                                            data-cy='acceptanceSkills'
                                            type='checkbox'
                                            value={jobPosition.id + '/' + levelOfExpertise}
                                        />
                                        <span className='mt-1'>{intl.formatMessage({id: "ENUM.LEVEL_OF_EXPERTISE"}, { levelOfExpertise: levelOfExpertise })}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    ))}
                    {formikAcceptanceSkills.touched.acceptanceSkills && formikAcceptanceSkills.errors.acceptanceSkills && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formikAcceptanceSkills.errors.acceptanceSkills}</span>
                            </div>
                        </div>
                    )}
                    <div className='text-center pt-15'>
                        <button
                            type='submit'
                            className='btn btn-primary me-3'
                        >
                            {(!formikAcceptanceSkills.isSubmitting) && (
                                <span className='indicator-label'>{intl.formatMessage({id: 'FORM.ACTION.SAVE'})}</span>
                            )}
                            {(formikAcceptanceSkills.isSubmitting) && (
                                <span>
                                {intl.formatMessage({id: 'COMMON.PLEASE_WAIT'})}{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            )}
                        </button>
                    </div>
                </form>
            )}
        </>
    );
}


export {AcceptanceSkills};