import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLayout} from '../../../../../../_metronic/layout/core'
import {useQuery} from "react-query";
import {
  KnowledgeGroupService
} from "../../../../../../services/requests";
import {useQueryRequest} from "../core/QueryRequestProvider";
import {initialQueryState} from "../../../../../../_metronic/helpers";
import {useAuth} from "../../../../../modules/auth";


const GroupsFilter = () => {

  const {state, updateState} = useQueryRequest();
  const intl = useIntl();

  const {currentUser} = useAuth()


  const [tab, setTab] = useState('Header')
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)

  const lineOfBusiness: string = "";
  const [group, setCurrentGroup] = useState('')

  const { data: groups } = useQuery({
    queryKey: ['groups'],
    queryFn: KnowledgeGroupService.findAllKnowledgeGroup,
  })

  const {setLayout} = useLayout()

  function handleGroupChange(newGroup: string) {
    updateState({group: newGroup, ...initialQueryState});
  }


  const updateConfig = () => {

  }

  const reset = () => {
    setResetLoading(true)
    setTimeout(() => {
      setCurrentGroup('')
      setResetLoading(false)
    }, 100)
  }

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  // @ts-ignore
  return (
      <>
      <select
          className='form-select'
          value={state.group}
          onChange={(e) => (
              handleGroupChange(e.target.value)
              )}
      >
        <option value="">{intl.formatMessage({id: 'TABLE.KNOWLEDGES.TECHNICAL_WORDS.GROUPS'})}</option>
        {groups?.map((group) => (
            <option value={group.id}>{group.labelFr}</option>
        ))}
      </select>

      </>
  )
}
export {GroupsFilter}
