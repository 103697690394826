import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery, useQueryClient} from "react-query";
import {
    JobPositionService,
    LineOfBusinessService,
    TechnicalWordService
} from "../../../../services/requests";
import {useLocation} from "react-router";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {useLang} from "../../../../_metronic/i18n/Metronici18n";
import {IntlLabelizer} from "../../../../utils/IntlLabelizer";

type Params = {
    id?: ID
}

const LineOfBusinessEditPage: FC = () => {
    const location = useLocation();
    const locationState = location?.state as Params;
    const intl = useIntl()
    const lang = useLang()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const queryClient = useQueryClient();

    const [lineOfBusinessId, setLineOfBusinessId] = useState(locationState?.id);


    const {data: lineOfBusiness, isLoading: isLineOfBusinessLoading, refetch: refetchLineOfBusiness} = useQuery({
            queryKey: ['lineOfBusiness', lineOfBusinessId],
            queryFn: () => {
                return LineOfBusinessService.findByIdLineOfBusiness(lineOfBusinessId as string);
            },
            enabled: !!lineOfBusinessId
        }
    )

    const { data: technicalWords, isLoading: isTechnicalWordsLoading, refetch: refreshTechnicalWords } = useQuery({
        queryKey: ['technicalWords', lineOfBusinessId],
        queryFn: () => {
            // Do something here
            //Todo change it
            return TechnicalWordService.findAllTechnicalWord();
        },
        enabled: !!lineOfBusinessId
    });

    const { data: jobPositions, isLoading: isJobPositionsLoading, refetch: refreshJobPositions } = useQuery({
        queryKey: ['jobPositions', lineOfBusinessId],
        queryFn: () => {
            // Do something here

            return JobPositionService.findAllByLineOfBusinessJobPosition(lineOfBusinessId as string);
        },
        enabled: !!lineOfBusinessId
    });

    const everythingLoaded = !isLineOfBusinessLoading && !isJobPositionsLoading && !isTechnicalWordsLoading;


    const pageTitle = lineOfBusiness ?
        intl.formatMessage({id: 'PAGE.LINE_OF_BUSINESS_EDIT.TITLE.UPDATE'}, {name: IntlLabelizer.getIntlLabel(lineOfBusiness, lang)})
        : intl.formatMessage({id: 'PAGE.LINE_OF_BUSINESS_EDIT.TITLE.NEW'});

    const refreshLineOfBusinessId = (newLineOfBusinessId: string) => {
        setLineOfBusinessId(newLineOfBusinessId);
        queryClient.removeQueries({ queryKey: ['lineOfBusiness', newLineOfBusinessId] });
        queryClient.removeQueries({ queryKey: ['lineOfBusinesses'] });
    }

    const breadCrumb: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'PAGE.LINE_OF_BUSINESSES.TITLE'}),
            path: '/admin/line-of-businesses',
            isSeparator: false,
            isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    return (
        <>
            <PageTitle breadcrumbs={breadCrumb}>
                {pageTitle}
            </PageTitle>
            <div className='card mb-5'>
                <div className='card-body'>
                    {everythingLoaded && (
                        <div className='mx-auto mw-600px w-100 pt-5'>
                            <MainInfos refreshLineOfBusiness={refetchLineOfBusiness} refreshLineOfBusinessId={refreshLineOfBusinessId} lineOfBusiness={lineOfBusiness} />
                        </div>
                    )}
                </div>
            </div>
        </>

    )
}

export {LineOfBusinessEditPage}
