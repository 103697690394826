import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLayout} from '../../../../../../_metronic/layout/core'
import clsx from "clsx";
import {
  LineOfBusinessService
} from "../../../../../../services/requests/services/LineOfBusinessService";
import {useQuery} from "react-query";
import {
  BusinessLogicInterviewTechnicalAdvisorService,
  GetTechnicalAdvisorsForInterview, JobPositionService, TechnicalAdvisorService, TechnicalWordDto, TechnicalWordService,

} from "../../../../../../services/requests";
import {InviteUsers} from "../../../../../../_metronic/partials";
import {useQueryRequest} from "../core/QueryRequestProvider";
import {initialQueryState} from "../../../../../../_metronic/helpers";
import {useAuth} from "../../../../../modules/auth";
import {useLang} from "../../../../../../_metronic/i18n/Metronici18n";
import {IntlLabelizer} from "../../../../../../utils/IntlLabelizer";


const LineOfBusinessFilter = () => {

  const {state, updateState} = useQueryRequest();
  const intl = useIntl();
  const lang = useLang()

  const {currentUser} = useAuth()


  const [tab, setTab] = useState('Header')
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)

  const lineOfBusiness: string = "";
  const [currentLineOfBusiness, setCurrentLineOfBusiness] = useState('')

  const { data: lineOfBusinesses } = useQuery({
    queryKey: ['lineOfBusinesses'],
    queryFn: LineOfBusinessService.findAllLineOfBusiness,
  })

  const canUserSearchLineOfBusiness = (lineOfBusinessToCheck: string) => {
    return(
        ((!currentUser?.lineOfBusinesses || currentUser?.lineOfBusinesses.length == 0) || !!currentUser?.lineOfBusinesses?.find(lob => lob == lineOfBusinessToCheck) )
    )

  }

  const lineOfBusinessesToUse = lineOfBusinesses?.filter(lob => canUserSearchLineOfBusiness(lob.id));

  const { data: technicalAdvisors } = useQuery({
    queryKey: ['technicalAdvisors', currentLineOfBusiness],
    queryFn: () => {
      // Do something here

      return TechnicalAdvisorService.findAllByLineOfBusinessTechnicalAdvisor(currentLineOfBusiness);
    },
    enabled: !!currentLineOfBusiness
  });

  const {setLayout} = useLayout()

  function handleLineOfBusinessChange(newLineOfBusiness: string) {
    updateState({lineOfBusiness: newLineOfBusiness, ...initialQueryState});
  }


  const updateConfig = () => {

  }

  const reset = () => {
    setResetLoading(true)
    setTimeout(() => {
      setCurrentLineOfBusiness('')
      setResetLoading(false)
    }, 100)
  }

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  // @ts-ignore
  return (
      <>
      <select
          className='form-select'
          value={state.lineOfBusiness}
          onChange={(e) => (
              handleLineOfBusinessChange(e.target.value)
              )}
      >
        <option value="">{intl.formatMessage({id: 'TABLE.TECHNICAL_ADVISORS.LINE_OF_BUSINESS'})}</option>
        {lineOfBusinessesToUse?.map((lineOfBusiness) => (
            <option value={lineOfBusiness.id}>{IntlLabelizer.getIntlLabel(lineOfBusiness, lang)}</option>
        ))}
      </select>

      </>
  )
}
export {LineOfBusinessFilter}
