// @ts-nocheck
import {Column} from 'react-table'
import {GroupLabelCell} from './GroupLabelCell'
import {GroupCustomHeader} from './GroupCustomHeader'
import {UserInfoDto} from "../../../../../../services/requests";



const usersColumns: ReadonlyArray<Column<UserInfoDto>> = [
  {
    Header: (props) => <GroupCustomHeader tableProps={props} title='TABLE.USERS.HEADER.NAME' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <GroupLabelCell label={props.data[props.row.index].name} id={props.data[props.row.index].id}  />,
  },
  {
    Header: (props) => <GroupCustomHeader tableProps={props} title='TABLE.USERS.HEADER.EMAIL' className='min-w-125px' />,
    id: 'email',
    Cell: ({...props}) => <GroupLabelCell label={props.data[props.row.index].email} id={props.data[props.row.index].id}  />,
  }
]

export {usersColumns}
