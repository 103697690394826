import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import React from "react";
import {useIntl} from "react-intl";
import {GroupsListWrapper} from "./list/GroupsListPage";
import {GroupEditPage} from "./edit/GroupEditPage";


const GroupsRoutesDefinition = () => {
    const intl = useIntl()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PAGE.KNOWLEDGES.GROUPS.TITLE'})}</PageTitle>
              <GroupsListWrapper />
            </>
          }
        />
      </Route>
        <Route
            path='edit'
            element={
                <>
                    <GroupEditPage />
                </>
            }
        />
      <Route index element={<Navigate to='/admin/knowledges/groups/list' />} />
    </Routes>
  )
}

export default GroupsRoutesDefinition
