/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KnowledgeGroupLineOfBusinessCommand } from '../models/KnowledgeGroupLineOfBusinessCommand';
import type { KnowledgeGroupLineOfBusinessDto } from '../models/KnowledgeGroupLineOfBusinessDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class KnowledgeGroupLineOfBusinessService {

    /**
     * @param id 
     * @returns KnowledgeGroupLineOfBusinessDto OK
     * @throws ApiError
     */
    public static findByIdKnowledgeGroupLineOfBusiness(
id: string,
): CancelablePromise<KnowledgeGroupLineOfBusinessDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/line-of-businesses/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns KnowledgeGroupLineOfBusinessDto OK
     * @throws ApiError
     */
    public static updateKnowledgeGroupLineOfBusiness(
id: string,
requestBody: KnowledgeGroupLineOfBusinessCommand,
): CancelablePromise<KnowledgeGroupLineOfBusinessDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/knowledge-groups/line-of-businesses/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @returns KnowledgeGroupLineOfBusinessDto OK
     * @throws ApiError
     */
    public static deleteKnowledgeGroupLineOfBusiness(
id: string,
): CancelablePromise<KnowledgeGroupLineOfBusinessDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/knowledge-groups/line-of-businesses/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns KnowledgeGroupLineOfBusinessDto Created
     * @throws ApiError
     */
    public static createKnowledgeGroupLineOfBusiness(
requestBody: KnowledgeGroupLineOfBusinessCommand,
): CancelablePromise<KnowledgeGroupLineOfBusinessDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/knowledge-groups/line-of-businesses',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param group 
     * @returns KnowledgeGroupLineOfBusinessDto OK
     * @throws ApiError
     */
    public static findAllByKnowledgeGroupKnowledgeGroupLineOfBusiness(
group: string,
): CancelablePromise<Array<KnowledgeGroupLineOfBusinessDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/line-of-businesses/byKnowledgeGroup',
            query: {
                'group': group,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns KnowledgeGroupLineOfBusinessDto OK
     * @throws ApiError
     */
    public static findAllKnowledgeGroupLineOfBusiness(): CancelablePromise<Array<KnowledgeGroupLineOfBusinessDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/knowledge-groups/line-of-businesses/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
