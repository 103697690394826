import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery, useQueryClient} from "react-query";
import {
    JobPositionService,
    LineOfBusinessService, TechnicalAdvisorAcceptanceSkillDto, TechnicalAdvisorSAcceptanceSkillService,
    TechnicalAdvisorService, TechnicalWordDto,
    TechnicalWordService
} from "../../../../services/requests";
import {useLocation} from "react-router";
import {AcceptanceSkills} from "./components/AcceptanceSkills";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {BlockingInfos} from "./components/BlockingInfos";
import {useAuth} from "../../../modules/auth";
import {ToastNotificator, useToastData} from "../../../../utils/ToastNotificator";

type Params = {
  technicalAdvisorId?: ID,
  lineOfBusinessId: string
}

const TechnicalAdvisorEditPage: FC = () => {
    const location = useLocation();
    const locationState = location.state as Params;
    const intl = useIntl()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const {currentUser} = useAuth()
    const queryClient = useQueryClient();
    const [acceptanceSkills, setAcceptanceSkills] = useState<Array<TechnicalAdvisorAcceptanceSkillDto>>();
    const [technicalWords, setTechnicalWords] = useState<Array<TechnicalWordDto>>([]);

    const isCurrentUserAdmin = !!currentUser?.roles?.find(role => role == "LOB_ADMIN");
    const [technicalAdvisorId, setTechnicalAdvisorId] = useState(locationState.technicalAdvisorId);
    const [technicalWordsLoaded, setTechnicalWordsLoaded] = useState(false);

    const {
        isLoading: isTechnicalAdvisorLoading,
        data: technicalAdvisor,
    } = useQuery({
            queryKey: ['technicalAdvisor', technicalAdvisorId],
            queryFn: () => {
                return TechnicalAdvisorService.findByIdTechnicalAdvisor(technicalAdvisorId as string);
            },
            enabled: !!technicalAdvisorId
        }
    )


    const { data: jobPositions, isLoading: isJobPositionsLoading } = useQuery({
        queryKey: ['jobPositions', locationState.lineOfBusinessId],
        queryFn: () => {
            // Do something here

            return JobPositionService.findAllByLineOfBusinessJobPosition(locationState.lineOfBusinessId);
        }
    });

    const addAllElements = (originalList, newItems): string[] => {
        var newList = !!originalList?originalList:[];
        if(!!newItems) {
            newList = originalList.concat(newItems);
        }
        return newList;
    }



    useEffect(() => {
        if(!!acceptanceSkills) {
            const kGroups = jobPositions?.filter(job => acceptanceSkills?.find(as => as.jobPosition == job.id))?.map(job => job.knowledgeGroups).reduce(addAllElements, []);
            if(!!kGroups && kGroups.length > 0) {
                TechnicalWordService.findAllByKnowledgeGroups(kGroups).then(list => {
                    setTechnicalWords(list);
                    setTechnicalWordsLoaded(true);
                });
            } else {
                setTechnicalWords([]);
                setTechnicalWordsLoaded(true);
            }
        }
    }, [acceptanceSkills]);


    const loadAcceptanceSkills = () => {
        if(!!technicalAdvisorId) {
            TechnicalAdvisorSAcceptanceSkillService.findAllByTechnicalAdvisor(technicalAdvisorId as string).then(list => setAcceptanceSkills(list));
        } else {
            setAcceptanceSkills([]);
        }
    }

    useEffect(() => {
        loadAcceptanceSkills();
    }, [jobPositions]);


    const everythingLoaded = !!jobPositions && technicalWordsLoaded;


    const pageTitle = technicalAdvisor ?
        intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.TITLE.UPDATE'}, {fullName: technicalAdvisor?.name})
        : intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.TITLE.NEW'});

    const breadCrumb: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISORS.TITLE'}),
            path: '/admin/technical-advisors',
            isSeparator: false,
            isActive: true,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    const refreshTechnicalAdvisorId = (newTechnicalAdvisorId: string) => {
        setTechnicalAdvisorId(newTechnicalAdvisorId);
        queryClient.removeQueries({ queryKey: ['technicalAdvisor', newTechnicalAdvisorId] });
        queryClient.removeQueries({ queryKey: ['technicalAdvisors'] });
    }


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const changeTab = (newTabIndex: number) => {
      stepper.current?.goto(newTabIndex)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
      <>
        <PageTitle breadcrumbs={breadCrumb}>
            {pageTitle}
        </PageTitle>
        <div className='card mb-5'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-5'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav' onClick={() => changeTab(1)} role="button">
              <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.MENU.MAIN_INFOS'})}</h3>
            </div>

                <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(2)} role="button">
                      {!!technicalAdvisorId && (
                    <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.MENU.ACCEPTANCE_SKILLS'})}</h3>
                      )}
                </div>

              {isCurrentUserAdmin && (
                  <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(3)} role="button">
                      {!!technicalAdvisorId && (
                          <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.MENU.BLOCKING_INFOS'})}</h3>
                      )}
                  </div>
              )}

          </div>
            {everythingLoaded && (
            <div className='mx-auto mw-600px w-100 pt-5'>
                <div className='current' data-kt-stepper-element='content'>
                  <MainInfos refreshTechnicalAdvisorId={refreshTechnicalAdvisorId} technicalAdvisor={technicalAdvisor} lineOfBusinessId={locationState.lineOfBusinessId} technicalWords={technicalWords} />
                </div>
                <div data-kt-stepper-element='content'>
                    {technicalAdvisor && acceptanceSkills && (
                        <AcceptanceSkills technicalAdvisorId={technicalAdvisor.id} jobPositions={jobPositions} activeAcceptanceSkills={acceptanceSkills} reloadAcceptanceSkills={loadAcceptanceSkills} />
                    )}
                </div>
                {isCurrentUserAdmin && (
                    <div data-kt-stepper-element='content'>
                        {technicalAdvisor  && (
                            <BlockingInfos technicalAdvisor={technicalAdvisor} />
                        )}
                    </div>
                )}
            </div>
            )}
        </div>
      </div>
    </div>
    </>

  )
}

export {TechnicalAdvisorEditPage}
