// @ts-nocheck
import {Column} from 'react-table'
import {TechnicalWordLabelCell} from './TechnicalWordLabelCell'
import {GroupActionsCell} from './GroupActionsCell'
import {GroupCustomHeader} from './GroupCustomHeader'
import {TechnicalWordDto} from "../../../../../../../services/requests";
import {IntlLabelizer} from "../../../../../../../utils/IntlLabelizer";
import {useLang} from "../../../../../../../_metronic/i18n/Metronici18n";
import {TechnicalWordGroupsCell} from "./TechnicalWordGroupsCell";



const groupsColumns: ReadonlyArray<Column<TechnicalWordDto>> = [
  {
    Header: (props) => <GroupCustomHeader tableProps={props} title='TABLE.KNOWLEDGES.TECHNICAL_WORDS.HEADER.LABEL_FR' className='min-w-125px' />,
    id: 'labelFr',
    Cell: ({...props}) => <TechnicalWordLabelCell label={props.data[props.row.index].labelFr} id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <GroupCustomHeader tableProps={props} title='TABLE.KNOWLEDGES.TECHNICAL_WORDS.HEADER.LABEL_EN' className='min-w-125px' />,
    id: 'labelEn',
    Cell: ({...props}) => <TechnicalWordLabelCell label={props.data[props.row.index].labelEn} id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <GroupCustomHeader tableProps={props} title='TABLE.KNOWLEDGES.TECHNICAL_WORDS.HEADER.GROUPS' className='min-w-100px' />,
    id: 'groups',
    Cell: ({...props}) => <TechnicalWordGroupsCell groups={props.data[props.row.index].groups} id={props.data[props.row.index].id} lang={useLang()} />,
  },
  {
    Header: (props) => <GroupCustomHeader tableProps={props} title='TABLE.HEADER.ACTIONS' className='min-w-100px' />,
    id: 'actions',
    Cell: ({...props}) => <GroupActionsCell id={props.data[props.row.index].id} label={IntlLabelizer.getIntlLabel(props.data[props.row.index], useLang())}  />,
  }
]

export {groupsColumns}
