import React, {FC} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useQuery} from "react-query";
import {JobPositionService, LineOfBusinessService} from "../../../../services/requests";
import {Link, useNavigate} from "react-router-dom";
import {KTIcon} from "../../../../_metronic/helpers";
import {useAuth} from "../../../modules/auth";
import {useLang} from "../../../../_metronic/i18n/Metronici18n";
import {IntlLabelizer} from "../../../../utils/IntlLabelizer";
import {confirmAlert} from "react-confirm-alert";
import {useToastData} from "../../../../utils/ToastNotificator";


const LineOfBusinessesListPage: FC = () => {
    const intl = useIntl()
    const lang = useLang()
    const {currentUser} = useAuth()
    const navigate = useNavigate()
    const { data: lineOfBusinesses } = useQuery({
        queryKey: ['lineOfBusinesses'],
        queryFn: LineOfBusinessService.findAllLineOfBusiness,
    })
    const {showSuccessMessage, showErrorMessage} = useToastData();

    const isUserAdmin = !!currentUser?.roles?.find(role => role == "ADMIN");

    const canUserModifyLineOfBusiness = (lineOfBusinessToCheck: string) => {
        return(
            isUserAdmin
            &&
            ((!currentUser?.lineOfBusinesses || currentUser?.lineOfBusinesses.length == 0) || !!currentUser?.lineOfBusinesses?.find(lob => lob == lineOfBusinessToCheck) )
        )

    }


    const goToAdd = () => {
        navigate('/admin/line-of-businesses/edit');
    }

    const deleteItem = ({id, label}) => {

        confirmAlert({
            title: intl.formatMessage({id: 'FORM.ACTION.CONFIRM_NEEDED'}),
            message: intl.formatMessage({id: 'FORM.ACTION.CONFIRM_ACTION'}, {element: label}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'COMMON.YES'}),
                    onClick: () => processDeleteItem(id)
                },
                {
                    label: intl.formatMessage({id: 'COMMON.NO'})
                }
            ]
        });
    }

    const processDeleteItem = async (id) => {

        try {
            await LineOfBusinessService.deleteLineOfBusiness(id as string);
            showSuccessMessage();
            window.location.reload();
        }
        catch(ex) {
            showErrorMessage();
            console.error(ex);

        }
    }

    return (
     <>
         {/* begin::Row */}
         {lineOfBusinesses && (
             <div className='g-5 gx-xxl-8 mt-5'>
                 <div className={'card'}>
                     {/* begin::Header */}
                     <div className='card-header border-0 pt-5'>
                         <h3 className='card-title align-items-start flex-column'>
                             <span className='card-label fw-bold fs-3 mb-1 text-primary'>{intl.formatMessage({id: 'TABLE.LINE_OF_BUSINESSES.TITLE'})}</span>
                         </h3>
                         {isUserAdmin && (
                         <button type='button' className='btn btn-primary' onClick={goToAdd}>
                             <KTIcon iconName='plus' className='fs-2' />
                             {intl.formatMessage({id: 'TABLE.LINE_OF_BUSINESSES.NEW_LINE_OF_BUSINESS'})}
                         </button>
                         )}
                     </div>
                     {/* end::Header */}
                     {/* begin::Body */}
                     <div className='card-body py-3'>
                         {/* begin::Table container */}
                         <div className='table-responsive'>
                             {/* begin::Table */}
                             <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                 {/* begin::Table head */}
                                 <thead>
                                 <tr className='fw-bold text-muted'>
                                     <th className='min-w-325px'>{intl.formatMessage({id: 'TABLE.LINE_OF_BUSINESSES.HEADER.NAME'})}</th>
                                     <th className='min-w-125px'>{intl.formatMessage({id: 'TABLE.LINE_OF_BUSINESSES.HEADER.LEADER'})}</th>
                                     <th className='min-w-125px'>{intl.formatMessage({id: 'TABLE.LINE_OF_BUSINESSES.HEADER.EMAIL'})}</th>
                                     <th className='min-w-125px'>{intl.formatMessage({id: 'TABLE.HEADER.ACTIONS'})}</th>
                                 </tr>
                                 </thead>
                                 {/* end::Table head */}
                                 {/* begin::Table body */}
                                 <tbody>
                                 {lineOfBusinesses?.map((lineOfBusiness) => (
                                     <tr key={lineOfBusiness.id}>
                                         <td>
                                             <div className='d-flex align-items-center'>
                                                 <div className='d-flex justify-content-start flex-column'>
                                                     {canUserModifyLineOfBusiness(lineOfBusiness.id) ? (
                                                        <Link to='/admin/line-of-businesses/edit' className='text-dark fw-bold text-hover-primary fs-6' state={{id: lineOfBusiness.id}} >{IntlLabelizer.getIntlLabel(lineOfBusiness, lang)}</Link>
                                                     ) : (
                                                         <>
                                                         {IntlLabelizer.getIntlLabel(lineOfBusiness, lang)}
                                                         </>
                                                     )}
                                                 </div>
                                             </div>
                                         </td>
                                         <td>
                                             <div className='d-flex align-items-center'>
                                                 <div className='d-flex justify-content-start flex-column'>
                                                         {lineOfBusiness.leadName}
                                                 </div>
                                             </div>
                                         </td>
                                         <td>
                                             <div className='d-flex align-items-center'>
                                                 <div className='d-flex justify-content-start flex-column'>
                                                     {lineOfBusiness.email}
                                                 </div>
                                             </div>
                                         </td>
                                         <td>
                                             <div className='d-flex align-items-center'>
                                                 {canUserModifyLineOfBusiness(lineOfBusiness.id) ? (
                                                     <div className='menu-item px-3'>
                                                         <a className='menu-link px-3' onClick={() => deleteItem({id: lineOfBusiness.id, label:IntlLabelizer.getIntlLabel(lineOfBusiness, lang)})}>
                                                             <i className='bi bi-trash text-primary px-3'></i>
                                                         </a>
                                                     </div>
                                                     )
                                                     :(
                                                      <> </>
                                                     )}
                                             </div>
                                         </td>
                                     </tr>
                                 ))}
                                 </tbody>
                                 {/* end::Table body */}
                             </table>
                             {/* end::Table */}
                         </div>
                         {/* end::Table container */}
                     </div>
                     {/* begin::Body */}
                 </div>
             </div>
         )}
         {/* end::Row */}
     </>
    )
}

export {LineOfBusinessesListPage};