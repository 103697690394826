import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import React from "react";
import {useIntl} from "react-intl";
import {JobPositionsListWrapper} from "./list/JobPositionsListPage";
import {JobPositionEditPage} from "./edit/JobPositionEditPage";


const JobPositionsRoutesDefinition = () => {
    const intl = useIntl()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PAGE.JOB_POSITIONS.TITLE'})}</PageTitle>
              <JobPositionsListWrapper />
            </>
          }
        />
      </Route>
        <Route
            path='edit'
            element={
                <>
                    <JobPositionEditPage />
                </>
            }
        />
      <Route index element={<Navigate to='/admin/line-of-businesses/job-positions/list' />} />
    </Routes>
  )
}

export default JobPositionsRoutesDefinition
