import {PageTitle} from "../../../_metronic/layout/core";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {SearchForm, InterviewContentQuestionsSearchFormValues} from "./components/SearchForm";
import {SearchResults} from "./components/SearchResults";
import {useQuery} from "react-query";
import {
    JobPositionService,
    LineOfBusinessDto,
    LineOfBusinessService,
    TechnicalWordService
} from "../../../services/requests";
import {useAuth} from "../../modules/auth";


export const QuestionsForInterviewPage = () => {
    const intl = useIntl();

    const {currentUser} = useAuth()

    const [lineOfBusiness, setLineOfBusiness] = useState<string>();
    const [currentSearch, setCurrentSearch] = useState<InterviewContentQuestionsSearchFormValues>();

    const { data: lineOfBusinesses, isLoading: isLineOfBusinessesLoading } = useQuery({
        queryKey: ['lineOfBusinesses'],
        queryFn: LineOfBusinessService.findAllLineOfBusiness,
        staleTime: 300000
    })

    const canUserSearchLineOfBusiness = (lineOfBusinessToCheck: string) => {
        return(
            ((!currentUser?.lineOfBusinesses || currentUser?.lineOfBusinesses.length == 0) || !!currentUser?.lineOfBusinesses?.find(lob => lob == lineOfBusinessToCheck) )
        )

    }

    const lineOfBusinessesToUse = lineOfBusinesses?.filter(lob => canUserSearchLineOfBusiness(lob.id));

    const { data: jobPositions, isLoading: isJobPositionsLoading } = useQuery({
        queryKey: ['jobPositions', lineOfBusiness],
        queryFn: () => {
            // Do something here

            return JobPositionService.findAllByLineOfBusinessJobPosition(lineOfBusiness as string);
        },
        enabled: !!lineOfBusiness,
        staleTime: 300000
    });

    const { data: technicalWords, isLoading: isTechnicalWordsLoading } = useQuery({
        queryKey: ['technicalWords', lineOfBusiness],
        queryFn: () => {
            // Do something here
            //Todo : change the way it is initialized
            return TechnicalWordService.findAllTechnicalWord();
        },
        enabled: !!lineOfBusiness,
        staleTime: 300000
    });

    const everythingIsLoaded = !isLineOfBusinessesLoading && !isJobPositionsLoading && !isTechnicalWordsLoading;

    const manageLineOfBusinessChange = (lineOfBusiness: string) : void => {
        setLineOfBusiness(lineOfBusiness);
    }

    const launchSearch = (values: InterviewContentQuestionsSearchFormValues) => {
        setCurrentSearch(values);
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({id: 'PAGE.SEARCH.TITLE'})}
            </PageTitle>
            <div className="card my-5">
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1 text-primary'>{intl.formatMessage({id: 'FORM.SEARCH.TITLE'})}</span>
                    </h3>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    { everythingIsLoaded && <SearchForm
                        currentLineOfBusiness={lineOfBusiness}
                        lineOfBusinesses={lineOfBusinessesToUse as LineOfBusinessDto[]}
                        jobPositions={jobPositions}
                        onLineOfBusinessChange={manageLineOfBusinessChange}
                        onSubmit={launchSearch}
                    />}
                </div>
                {/* end::Body */}
            </div>
            {currentSearch && everythingIsLoaded && (
            <div className="card my-5">
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                        <SearchResults
                            searchValues={currentSearch}
                        />
                </div>
                {/* end::Body */}
            </div>
            )}
        </>
    )

}

