import clsx from "clsx";
import {
    GetTechnicalAdvisorsForInterview,
    JobPositionDto, JobPositionService,
    LineOfBusinessDto, LineOfBusinessService,
    TechnicalWordDto, TechnicalWordService
} from "../../../../services/requests";
import {FormattedMessage, useIntl} from "react-intl";
import Select from "react-select";
import React, {FC, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {CustomSelect} from "./CustomSelect";
import {useQuery} from "react-query";
import {useAuth} from "../../../modules/auth";
import {useLang} from "../../../../_metronic/i18n/Metronici18n";
import {IntlLabelizer} from "../../../../utils/IntlLabelizer";

export type SearchFormValues = {
    lineOfBusiness: string
    jobPosition: string
    lang: string
    levelOfExpertise: string
    technicalWords?: string[]
    allTechnicalWords?: TechnicalWordDto[]
}

type Props = {
    onSubmit: SubmitHandler<any>
}

export const SearchForm: FC<Props> = ({onSubmit}) => {
    const intl = useIntl();
    const lang = useLang()

    const {currentUser} = useAuth()

    const [lineOfBusiness, setLineOfBusiness] = useState<string>();
    const [jobPosition, setJobPosition] = useState<string>();

    const { data: lineOfBusinesses, isLoading: isLineOfBusinessesLoading } = useQuery({
        queryKey: ['lineOfBusinesses'],
        queryFn: LineOfBusinessService.findAllLineOfBusiness,
        staleTime: 300000
    })

    const canUserSearchLineOfBusiness = (lineOfBusinessToCheck: string) => {
        return(
            ((!currentUser?.lineOfBusinesses || currentUser?.lineOfBusinesses.length == 0) || !!currentUser?.lineOfBusinesses?.find(lob => lob == lineOfBusinessToCheck) )
        )

    }

    const lineOfBusinessesToUse = lineOfBusinesses?.filter(lob => canUserSearchLineOfBusiness(lob.id));


    const { data: jobPositions, isLoading: isJobPositionsLoading } = useQuery({
        queryKey: ['jobPositions', lineOfBusiness],
        queryFn: () => {
            // Do something here
            if(!!lineOfBusiness) {
                return JobPositionService.findAllByLineOfBusinessJobPosition(lineOfBusiness as string);
            } else {
                return JobPositionService.findAllJobPosition();
            }

        },
        staleTime: 300000
    });

    const { data: technicalWords, isLoading: isTechnicalWordsLoading } = useQuery({
        queryKey: ['technicalWords', jobPosition],
        queryFn: () => {
            // Do something here
            // Todo change it
            const kgroups = jobPositions?.find(job => job.id == jobPosition)?.knowledgeGroups;
            if(!!kgroups) {
                return TechnicalWordService.findAllByKnowledgeGroups(kgroups);
            } else {
                return [];
            }
        },
        enabled: !!jobPosition && !!jobPositions,
        staleTime: 300000
    });

    const everythingIsLoaded = !isLineOfBusinessesLoading && !isJobPositionsLoading;

    const manageLineOfBusinessChange = (lineOfBusiness: string) : void => {
        setLineOfBusiness(lineOfBusiness);
    }

    const manageJobPositionChange = (jobPosition: string) : void => {
        setJobPosition(jobPosition);
    }

    const methods = useForm({
        mode: "onSubmit",
        defaultValues: {
            lineOfBusiness: lineOfBusiness,
            jobPosition: undefined,
            lang: undefined,
            levelOfExpertise: undefined,
            technicalWords: undefined
        },
    });

    const {formState: { errors }} = useForm();
    const onSubmitInternal = (values) => {
        onSubmit( {
            lineOfBusiness: values.lineOfBusiness,
            jobPosition: values.jobPosition.value,
            lang: values.lang.value,
            levelOfExpertise: values.levelOfExpertise.value,
            technicalWords: values.technicalWords?.map(tw => tw.value),
            allTechnicalWords: technicalWords
        })

    }

    const allRequiredProperties = !!methods.watch("lang") && !!methods.watch("levelOfExpertise") && !!methods.watch("jobPosition")

    return (
        <>
            <form className='form' onSubmit={methods.handleSubmit(onSubmitInternal)}>
                    <div>
                        <div id="mainDiv" className='row mb-10'>
                            {!!lineOfBusinessesToUse && (
                            <div id="lineOfBusinessDiv" className='col-lg-6 col-xl-4 py-1 px-1'>
                                <select id="lineOfBusinessSelect" data-cy="cy-lineOfBusinessSelect"
                                    {...methods.register("lineOfBusiness", {
                                        onChange: e => manageLineOfBusinessChange(e.target.value)
                                    })}
                                    name="lineOfBusiness"
                                    className='form-select form-select-solid'
                                >
                                    <option value="">{intl.formatMessage({id: 'FORM.SEARCH.LINE_OF_BUSINESS'})}</option>
                                    {lineOfBusinessesToUse.map((lineOfBusiness) => (
                                        <option key={lineOfBusiness.id} data-cy="cy-{lineOfBusiness.id}" value={lineOfBusiness.id}>{IntlLabelizer.getIntlLabel(lineOfBusiness, lang)}</option>
                                    ))}
                                </select>
                            </div>
                            )}
                            <div id="jobPositionDiv" className='col-lg-6 col-xl-4 py-1 px-1'>
                                <Controller
                                    name="jobPosition"
                                    control={methods.control}
                                    render={({field }) => (
                                        <CustomSelect id="jobPositionSelect" data-cy="cy-jobPositionSelect"
                                          {...methods.register("jobPosition", {
                                              onChange: e => manageJobPositionChange(e.target.value.value)
                                          })}
                                          {...field}
                                            placeholder={intl.formatMessage({id: 'FORM.SEARCH.JOB_POSITION'}) + "*"}
                                            options={jobPositions?.map(jobPosition => {
                                                return {
                                                    id: jobPosition.id,
                                                    value: jobPosition.id,
                                                    label: IntlLabelizer.getIntlLabel(jobPosition, lang)
                                                };})}
                                        />
                                    )}
                                />
                            </div>
                            <div id="levelOfExpertiseDiv" className='col-lg-3 col-xl-2 py-1 px-1'>
                                <Controller
                                    name="levelOfExpertise"
                                    control={methods.control}
                                    render={({field }) => (
                                        <CustomSelect id="levelOfExpertiseSelect" data-cy="cy-levelOfExpertiseSelect"
                                            {...field}
                                            placeholder={intl.formatMessage({id: 'FORM.SEARCH.LEVEL_OF_EXPERTISE'}) + "*"}
                                            options={Object.keys(GetTechnicalAdvisorsForInterview.levelOfExpertise)?.map(levelOfExpertise => {
                                                return {
                                                    id: levelOfExpertise,
                                                    value: levelOfExpertise,
                                                    label: intl.formatMessage({id: "ENUM.LEVEL_OF_EXPERTISE"}, { levelOfExpertise: levelOfExpertise })
                                                };})}
                                        />
                                    )}
                                />
                            </div>
                            <div id="langDiv" className='col-lg-3 col-xl-2 py-1 px-1'>
                                <Controller
                                    name="lang"
                                    control={methods.control}
                                    render={({field }) => (
                                        <CustomSelect id="langSelect" data-cy="cy-langSelect"
                                            {...field}
                                            placeholder={intl.formatMessage({id: 'FORM.SEARCH.LANG'}) + "*"}
                                            options={Object.keys(GetTechnicalAdvisorsForInterview.lang)?.map(lang => {
                                                return {
                                                    id: lang,
                                                    value: lang,
                                                    label: intl.formatMessage({id: "ENUM.LANG"}, { lang: lang })
                                                };})}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div  id="technicalWordsDiv1" className='row mb-10'>
                            <div  id="technicalWordsDiv2" className='col-lg-12 py-1 px-1'>
                                <Controller
                                    name="technicalWords"
                                    control={methods.control}
                                    render={({field }) => (
                                        <CustomSelect id="technicalWordsSelect" data-cy="cy-technicalWordsSelect"
                                            {...field}
                                            placeholder={intl.formatMessage({id: 'FORM.SEARCH.TECHNICAL_WORDS'})}
                                            options={technicalWords?.map(technicalWord => {
                                                return {
                                                    id: technicalWord.id,
                                                    value: technicalWord.id,
                                                    label: IntlLabelizer.getIntlLabel(technicalWord, lang)
                                                };})}
                                            isMulti
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                <div className='row'>
                    <div className='col-lg-12 text-center'>
                        <button id="search" type='submit' className='btn btn-primary me-2' disabled={!allRequiredProperties}>
                            <span className='indicator-label'>{intl.formatMessage({id: 'FORM.SEARCH.SEARCH'})}</span>
                        </button>
                    </div>
                    {!allRequiredProperties && (
                        <div className='col-lg-12 text-center text-info p-5'>
                            {intl.formatMessage({id: 'FORM.SEARCH.MESSAGE.SPECIFY_ALL_REQUIRED_ARGS'})}
                        </div>
                    )}
                </div>
            </form>
        </>
    )
}

