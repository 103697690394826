import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'


import { UserInfoModel} from '../core/_models'
import {AuthenticationService, OpenAPI, UserService} from "../../../../services/requests";
import {useAuth} from '../core/Auth'


function Oauth2LoginRedirectSuccess() {
  const [redirectTo] = useState('/')
  const location = useLocation()

  const {saveAuth, setCurrentUser} = useAuth()

  OpenAPI.TOKEN = undefined;
  const getCurrentUser = async (token : any) => {
    saveAuth({api_token: token})
    OpenAPI.TOKEN = token;
    const user = await AuthenticationService.getCurrentUser()
    setCurrentUser(user as UserInfoModel)
}
  useEffect(() => {
    const accessToken = extractUrlParameter('token')
    getCurrentUser(accessToken);
  }, [])

  const extractUrlParameter = (key) => {
    return new URLSearchParams(location.search).get(key)
  }
  return <Navigate to={redirectTo} />
}

export default Oauth2LoginRedirectSuccess