import Toast from 'react-bootstrap/Toast';
import {createContext, FC, useContext, useState} from "react";
import {ToastContainer} from "react-bootstrap";
import {WithChildren} from "../_metronic/helpers";
import {useIntl} from "react-intl";

export declare type ToastMessageType = 'success' | 'error';

export interface ToastDataContextModel {
    show: boolean
    setShow: (_show: boolean) => void
    message: string
    setMessage: (_message: string) => void
    messageType: ToastMessageType
    setMessageType: (_messageType: ToastMessageType) => void
    showSuccessMessage: (_message?: string) => void
    showErrorMessage: (_message?: string) => void
};

const ToastDataContext = createContext<ToastDataContextModel>({
    show: false,
    setShow: (_show: boolean) => {},
    message: "",
    setMessage: (_message: string) => {},
    messageType: "success",
    setMessageType: (_messageType: ToastMessageType) => {},
    showSuccessMessage: (_message?: string) => {},
    showErrorMessage: (_message?: string) => {},
});

const ToastDataProvider: FC<WithChildren> = ({children}) => {
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('')
    const [messageType, setMessageType] = useState<ToastMessageType>('success')

    const showSuccessMessage = (message) => {
        setShow(true);
        setMessage(message);
        setMessageType("success");
    }

    const showErrorMessage = (message) => {
        setShow(true);
        setMessage(message);
        setMessageType("error");
    }

    const value: ToastDataContextModel = {
        show,
        setShow,
        message,
        setMessage,
        messageType,
        setMessageType,
        showSuccessMessage,
        showErrorMessage
    }
    return <ToastDataContext.Provider value={value}>{children}</ToastDataContext.Provider>
}

function useToastData() {
    return useContext(ToastDataContext)
}

const ToastNotificator: FC = () => {
    const {show, setShow, message, messageType} = useToastData();
    const intl = useIntl();
    let autohide = messageType == "success";
    let messageId = messageType == "success"?"TOAST.MESSAGE.SUCCESS":"TOAST.MESSAGE.ERROR";
    let actualMessage = !!message? message : intl.formatMessage({id: messageId});
    let usedBi =  messageType == "success"?"fw-bold text-success bi bi-check2-circle":"fw-bold text-danger bi bi-exclamation-circle";
    return (
        <ToastContainer position={"middle-center"}>
            <Toast onClose={() => setShow(false)} show={show} autohide={autohide}  delay={3000} >
              <Toast.Header className={messageType == "success"?"alert-success":"alert-danger"}>
                  <strong className="me-auto">
                      <i className={usedBi}></i>
                  </strong>
              </Toast.Header>
              <Toast.Body
              className={messageType == "success"?"alert-success":"alert-danger"}>
                  <span
                      className="fw-bold "
                  >
                      {actualMessage}
                  </span>
              </Toast.Body>
            </Toast>
        </ToastContainer>
  );
}

export {ToastDataProvider, ToastNotificator, useToastData};