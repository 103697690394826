/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserInfoCommand } from '../models/UserInfoCommand';
import type { UserInfoDto } from '../models/UserInfoDto';
import type { UserRoleDto } from '../models/UserRoleDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param id 
     * @returns UserInfoDto OK
     * @throws ApiError
     */
    public static findByIdUser(
id: string,
): CancelablePromise<UserInfoDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns UserInfoDto OK
     * @throws ApiError
     */
    public static updateUser(
id: string,
requestBody: UserInfoCommand,
): CancelablePromise<UserInfoDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @returns UserInfoDto OK
     * @throws ApiError
     */
    public static deleteUser(
id: string,
): CancelablePromise<UserInfoDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserInfoDto Created
     * @throws ApiError
     */
    public static createUser(
requestBody: UserInfoCommand,
): CancelablePromise<UserInfoDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns UserRoleDto OK
     * @throws ApiError
     */
    public static getAllAvailableUserRoles(): CancelablePromise<Array<UserRoleDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/available-roles',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns UserInfoDto OK
     * @throws ApiError
     */
    public static findAllUser(): CancelablePromise<Array<UserInfoDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
