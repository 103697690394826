/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SelfTechnicalAdvisorCommand } from '../models/SelfTechnicalAdvisorCommand';
import type { SelfTechnicalAdvisorDto } from '../models/SelfTechnicalAdvisorDto';
import type { TechnicalAdvisorChangeBlockedStatusCommand } from '../models/TechnicalAdvisorChangeBlockedStatusCommand';
import type { TechnicalAdvisorCommand } from '../models/TechnicalAdvisorCommand';
import type { TechnicalAdvisorDto } from '../models/TechnicalAdvisorDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TechnicalAdvisorService {

    /**
     * @param id 
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static findByIdTechnicalAdvisor(
id: string,
): CancelablePromise<TechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisors/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static updateTechnicalAdvisor(
id: string,
requestBody: TechnicalAdvisorCommand,
): CancelablePromise<TechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/technical-advisors/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static deleteTechnicalAdvisor(
id: string,
): CancelablePromise<TechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/technical-advisors/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns SelfTechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static self(): CancelablePromise<SelfTechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisors/self',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SelfTechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static updateSelf(
requestBody: SelfTechnicalAdvisorCommand,
): CancelablePromise<SelfTechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/technical-advisors/self',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static changeBlockedStatus(
requestBody: TechnicalAdvisorChangeBlockedStatusCommand,
): CancelablePromise<TechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/technical-advisors/changeBlockedStatus',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TechnicalAdvisorDto Created
     * @throws ApiError
     */
    public static createTechnicalAdvisor(
requestBody: TechnicalAdvisorCommand,
): CancelablePromise<TechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/technical-advisors',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param lineOfBusiness 
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static findAllByLineOfBusinessTechnicalAdvisor(
lineOfBusiness: string,
): CancelablePromise<Array<TechnicalAdvisorDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisors/byLineOfBusiness',
            query: {
                'lineOfBusiness': lineOfBusiness,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static findAllTechnicalAdvisor(): CancelablePromise<Array<TechnicalAdvisorDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisors/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
