/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SelfTechnicalAdvisorCommand = {
    id?: string;
    langs: Array<'FR' | 'EN'>;
    technicalWords?: Array<string>;
    region?: SelfTechnicalAdvisorCommand.region;
    fulltimeAvailable?: boolean;
};

export namespace SelfTechnicalAdvisorCommand {

    export enum region {
        QUEBEC = 'QUEBEC',
        CANADA = 'CANADA',
        USA = 'USA',
        FRANCE = 'FRANCE',
        MAROC = 'MAROC',
        INDIA = 'INDIA',
    }


}
