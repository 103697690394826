import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery, useQueryClient} from "react-query";
import {
    JobPositionService,
    LineOfBusinessService, TechnicalAdvisorAcceptanceSkillDto, TechnicalAdvisorSAcceptanceSkillService,
    TechnicalAdvisorService, TechnicalWordDto,
    TechnicalWordService
} from "../../../../services/requests";
import {useLocation} from "react-router";
import {AcceptanceSkills} from "./components/AcceptanceSkills";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {BlockingInfos} from "./components/BlockingInfos";
import {useAuth} from "../../../modules/auth";
import {ToastNotificator, useToastData} from "../../../../utils/ToastNotificator";
import {MainInfosSelf} from "./components/MainInfosSelf";

type Params = {
  technicalAdvisorId?: ID,
  lineOfBusinessId: string
}

const SelfEditPage: FC = () => {
    // const location = useLocation();
    // const locationState = location.state as Params;
    const intl = useIntl()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const {currentUser} = useAuth()
    const queryClient = useQueryClient();

    const [acceptanceSkills, setAcceptanceSkills] = useState<Array<TechnicalAdvisorAcceptanceSkillDto>>();
    const [technicalWords, setTechnicalWords] = useState<Array<TechnicalWordDto>>([]);
    const [technicalWordsLoaded, setTechnicalWordsLoaded] = useState(false);

    const {
        isLoading: isTechnicalAdvisorLoading,
        data: technicalAdvisor,
    } = useQuery({
            queryKey: ['technicalAdvisorSelf'],
            queryFn: () => {
                return TechnicalAdvisorService.self();
            }
        }
    )


    const { data: jobPositions, isLoading: isJobPositionsLoading } = useQuery({
        queryKey: ['jobPositions', technicalAdvisor?.lineOfBusiness],
        queryFn: () => {
            // Do something here

            return JobPositionService.findAllByLineOfBusinessJobPosition(technicalAdvisor?.lineOfBusiness as string);
        },
        enabled : !!technicalAdvisor
    });

    const addAllElements = (originalList, newItems): string[] => {
        var newList = !!originalList?originalList:[];
        if(!!newItems) {
            newList = originalList.concat(newItems);
        }
        return newList;
    }



    useEffect(() => {
        if(!!acceptanceSkills) {
            const kGroups = jobPositions?.filter(job => acceptanceSkills?.find(as => as.jobPosition == job.id))?.map(job => job.knowledgeGroups).reduce(addAllElements, []);
            if(!!kGroups && kGroups.length > 0) {
                TechnicalWordService.findAllByKnowledgeGroups(kGroups).then(list => {
                    setTechnicalWords(list);
                    setTechnicalWordsLoaded(true);
                });
            } else {
                setTechnicalWords([]);
                setTechnicalWordsLoaded(true);
            }
        }
    }, [acceptanceSkills]);


    const loadAcceptanceSkills = () => {
        if(!!technicalAdvisor) {
            TechnicalAdvisorSAcceptanceSkillService.findAllForCurrentUser().then(list => setAcceptanceSkills(list));
        }
    }

    useEffect(() => {
        loadAcceptanceSkills();
    }, [jobPositions]);


    const everythingLoaded = technicalAdvisor && jobPositions && technicalWordsLoaded;

    const refreshTechnicalAdvisorId = (newTechnicalAdvisorId: string) => {
        queryClient.removeQueries({ queryKey: ['technicalAdvisorSelf'] });
    }


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const changeTab = (newTabIndex: number) => {
      stepper.current?.goto(newTabIndex)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
      <>
        {/*<PageTitle breadcrumbs={breadCrumb}>*/}
        {/*    {pageTitle}*/}
        {/*</PageTitle>*/}
        <div className='card mb-5'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-5'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav' onClick={() => changeTab(1)} role="button">
              <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.MENU.MAIN_INFOS'})}</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(2)} role="button">
                <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.MENU.ACCEPTANCE_SKILLS'})}</h3>
            </div>
          </div>
            {everythingLoaded && (
            <div className='mx-auto mw-600px w-100 pt-5'>
                <div className='current' data-kt-stepper-element='content'>
                  <MainInfosSelf refreshTechnicalAdvisorId={refreshTechnicalAdvisorId} technicalAdvisor={technicalAdvisor} technicalWords={technicalWords} />
                </div>
                <div data-kt-stepper-element='content'>
                    {technicalAdvisor && acceptanceSkills && (
                        <AcceptanceSkills technicalAdvisorId={technicalAdvisor.id} jobPositions={jobPositions} activeAcceptanceSkills={acceptanceSkills} reloadAcceptanceSkills={loadAcceptanceSkills} />
                    )}
                </div>
            </div>
            )}
        </div>
      </div>
    </div>
    </>

  )
}

export {SelfEditPage}
